// markerUtils.js
import L from 'leaflet';

export const addStartMarker = (map, startPoint, markerRef) => {
  if (markerRef.current) {
    map.removeLayer(markerRef.current); // Remove old marker
  }
  markerRef.current = L.marker(startPoint, {
    icon: L.divIcon({ className: 'start-point', html: '<div class="start-pin"></div>', iconSize: [20, 20] }),
  }).addTo(map);
};

export const removeStartMarker = (map, markerRef) => {
  if (markerRef.current) {
    map.removeLayer(markerRef.current);
    markerRef.current = null;
  }
};

export const addEndMarker = (map, endPoint, markerRef) => {
  if (markerRef.current) {
    map.removeLayer(markerRef.current); // Remove old marker
  }
  markerRef.current = L.marker(endPoint, {
    icon: L.divIcon({ className: 'end-point', html: '<div class="end-pin"></div>', iconSize: [20, 20] }),
  }).addTo(map);
};

export const removeEndMarker = (map, markerRef) => {
  if (markerRef.current) {
    map.removeLayer(markerRef.current);
    markerRef.current = null;
  }
};
