import React from 'react';
import i18n from '../../../../../../i18n';

const TimelineLegend = () => (
  <div className="vehicle-page-timeline-card legend-container">
    <svg width="150px" height="100px">
      <rect x="30" y="10" width="15" height="15" stroke="white" strokeWidth="2" fill="#10b43e" />
      <text x="50" y="23" fill="white">
        {i18n.t('vehicle_page_timeline_legend_active')}
      </text>
      <rect x="30" y="40" width="15" height="15" stroke="white" strokeWidth="2" fill="rgb(255, 201, 22)" />
      <text x="50" y="53" fill="white">
        {i18n.t('vehicle_page_timeline_legend_idle')}
      </text>
      <rect x="30" y="70" width="15" height="15" stroke="white" strokeWidth="2" fill="gray" />
      <text x="50" y="83" fill="white">
        {i18n.t('vehicle_page_timeline_legend_offline')}
      </text>
    </svg>
  </div>
);


export default TimelineLegend;
