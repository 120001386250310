import React, { useState, useEffect, useRef } from 'react';
import L, { Marker } from 'leaflet';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FleetDeviceAPIUrl, FleetFormalAPIUrl, GetDemoDate } from '../../GlobalDefine';
import '../../css/statistic_page/StatisticPageMap.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rotatedmarker';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.fullscreen';

const customIcon = (data) => {
  let pointColor;

  if (data.is_online === false) {
    pointColor = 'gray';
  } else if (data.idle) {
    pointColor = 'yellow';
  } else if (data.is_online === true) {
    pointColor = 'green';
  } else {
    pointColor = 'gray';
  }

  // Use HTML div with a colored circle instead of an image
  return L.divIcon({
    className: 'custom-div-icon', // Custom class for the icon
    html: `<div style="
            background-color: ${pointColor};
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid white;
            "></div>`, // Circle with dynamic color
    iconSize: [20, 20],
    iconAnchor: [10, 10], // Adjust anchor point so it centers properly
    popupAnchor: [0, -15],
  });
};

const StatisticPageMap = ({
  currentDevice,
  fleetStatus,
}) => {
  // Use driverLocations if provided
  const [deviceList, setDeviceList] = useState([]);
  const mapRef = useRef(null);
  const markerGroupRef = useRef(null);
  const mapInit = useRef(false);
  const componentIsMountedRef = useRef(true);
  const history = useHistory();
  const controller = new AbortController();
  const controllerIdle = new AbortController();
  const initCoordinate = currentDevice?.last_location || [25.0, 121.5];
  const mapInitRef = useRef(false);
  const [completeData, setCompleteData] = useState([]);

  const handleMarkerClick = (device) => {
    const redirectPath = `/vehicle/${device.device_id}`;
    history.push(redirectPath);
  };

  const customPopup = (device) => {
    const driver_image_path = device ? device.image_path : 'no data';
    const url = FleetFormalAPIUrl.slice(0, -1) + driver_image_path;

    return `
      <div style="text-align: center; padding: 10px;">
        <img src="${url}" alt="${device.driver_name}" style="width: 80px; height: 80px; border-radius: 50%; border: 2px solid #ccc; margin-bottom: 10px;">
        <div style="font-size: 14px; color: #333;">
          <strong>Driver:</strong> ${device.driver_name || 'Unknown'}<br>
          <strong>Car:</strong> ${device.car_num || 'Unknown'}<br>
        </div>
      </div>
    `;
  };

  const createMarker = () => {
    setCompleteData(fleetStatus);

    // Clear all existing markers from the map
    markerGroupRef.current.clearLayers();
    let markerExist = false;
    fleetStatus.forEach((data) => {
      markerExist = true;
      const lat = data.latitude;
      const lon = data.longitude;

      const marker = new Marker([lat, lon], {
        icon: customIcon(data),
      })
        .bindPopup(customPopup(data))
        .bindTooltip(data.car_num, { permanent: false, direction: 'top' })
        .addTo(markerGroupRef.current)
        .on('click', () => marker.openPopup())
        .on('dblclick', () => handleMarkerClick(data));
    });

    if (markerExist === true) {
      markerGroupRef.current.addTo(mapRef.current);
      if (mapInit.current === false) {
        mapRef.current.flyToBounds(markerGroupRef.current.getBounds(),
          { animation: true, duration: 2 });
        mapInit.current = true;
      }
    }
  };

  const getInfo = () => {
    setDeviceList(fleetStatus);
    createMarker(); // Use deviceList for markers
  };

  const initMap = () => {
    const streetMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      detectRetina: true,
      maxZoom: 19,
      maxNativeZoom: 20,
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
    });

    const darkMap = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
      detectRetina: true,
      maxZoom: 19,
      maxNativeZoom: 20,
      attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; OpenStreetMap contributors',
    });

    const brightMap = L.tileLayer('https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png', {
      detectRetina: true,
      maxZoom: 19,
      maxNativeZoom: 20,
      attribution: '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
    });

    mapRef.current = L.map('map', {
      center: initCoordinate,
      zoom: 17,
      zoomControl: true,
      layers: [streetMap],
    });

    const baseMaps = {
      Streets: streetMap,
      Night: darkMap,
      Bright: brightMap,
    };

    L.control.layers(baseMaps).addTo(mapRef.current);
    L.control.scale({ position: 'bottomright' }).addTo(mapRef.current);
    L.control.fullscreen({ position: 'topright' }).addTo(mapRef.current);
  };

  const initMarkerGroup = () => {
    markerGroupRef.current = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
      iconCreateFunction(cluster) {
        return L.divIcon({
          html: `<b>${cluster.getChildCount()}</b>`,
          className: 'statistic-page-map-custom-marker-cluster',
          iconAnchor: [30, 60],
          iconSize: [50, 50],
        });
      },
    });
  };

  useEffect(() => {
    componentIsMountedRef.current = true;
    if (mapInitRef.current === false) {
      initMap();
      initMarkerGroup();
      mapInitRef.current = true;
    }
    getInfo();

    return () => {
      componentIsMountedRef.current = false;
      controller.abort();
      controllerIdle.abort();
    };
  }, [fleetStatus]);

  const flyToCurrentDevice = () => {
    const device = completeData.find((item) => item.device_id === currentDevice.device_id);
    if (device && device.latitude && device.longitude) {
      let lat;
      let lon;
      if (device.latitude !== 0 && device.longitude !== 0) {
        lat = device.latitude;
        lon = device.longitude;
      }
      const latLngBounds = L.latLngBounds([
        [lat - 0.0004, lon - 0.0004],
        [lat + 0.0004, lon + 0.0004],
      ]);
      mapRef.current.flyToBounds(latLngBounds, {
        padding: [50, 50],
        duration: 2,
      });
    }
  };

  useEffect(() => {
    if (currentDevice?.device_id) {
      flyToCurrentDevice();
    }
  }, [currentDevice]);

  return (
    <div className="statistic-page-map container" id="map" />
  );
};

StatisticPageMap.propTypes = {
  currentDevice: PropTypes.shape({
    device_id: PropTypes.string.isRequired,
    last_location: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default StatisticPageMap;
