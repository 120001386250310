import React, { useState, useEffect } from 'react';
// import ReactNotifications from 'react-notifications-component';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import {
  Redirect, BrowserRouter as Switch, Route, BrowserRouter,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from './i18n';
import NavigationBar from './components/NavigationBar';
// import LoginPage from './components/main_pages/LoginPage';
import { Routes } from './Route';
import { GetLoginInfo, SetLoginInfo } from './LoginInfo';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './components/css/NavigationBar.css';
import './components/css/Modal.css';
import './components/css/MainLayout.css';
import { FleetFormalAPIUrl } from './components/GlobalDefine';
import FleetPage from './components/main_pages/FleetPage';
import DriverPage from './components/main_pages/DriverPage';
import VehiclePage from './components/main_pages/VehiclePage';
import EventPage from './components/main_pages/EventPage';
import CoachPage from './components/main_pages/CoachPage';
import CoachPageDetail from './components/main_pages/coach_page_components/CoachPageDetail';
import LoginPage from './components/main_pages/LoginPage';
import DispatchPage from './components/main_pages/dispatch_page_components/DispatchPage';
import VehiclePageUtilization from './components/main_pages/vehicle_page_components/VehiclePageUtilization';
// import VehiclePageFuel from './components/main_pages/vehicle_page_components/VehiclePageFuel';
import DriverPageIndicator from './components/main_pages/driver_page_components/DriverPageIndicator';
import ExtraInfo from './components/ExtraInfo';
import FleetUnregisterDevicePage from './components/main_pages/fleet_page_components/FleetUnregisterDevicePage';
import FleetPageHeadline from './components/main_pages/fleet_page_components/FleetPageHeadline';
import RegisterDriverPage from './components/main_pages/driver_page_components/RegisterDriverPage';
import DispatchPageAddTask from './components/main_pages/dispatch_page_components/DispatchPageAddTask';
import TaskPage from './components/main_pages/TaskPage';
import SettingsPageNotification from './components/main_pages/settings_page_components/SettingsPageNotification';
import TunePage from './components/main_pages/settings_page_components/TunePage';
import FleetNavBar from './components/main_pages/fleet_page_components/FleetNavBar';
import CoachNavBar from './components/main_pages/coach_page_components/CoachNavBar';
import DriverNavBar from './components/main_pages/driver_page_components/DriverNavBar';
import VehicleNavBar from './components/main_pages/vehicle_page_components/VehicleNavBar';
import EventNavBar from './components/main_pages/event_page_components/EventNavBar';
import DispatchNavBar from './components/main_pages/dispatch_page_components/DispatchNavBar';
import SettingsNavBar from './components/main_pages/settings_page_components/SettingsNavBar';
import TestCaseNavBar from './components/main_pages/test_case_page_components/TestCaseNavBar';
import TestCasePage from './components/main_pages/TestCasePage';
import TestStatisticPage from './components/main_pages/TestStatisticPage';
import MemberImg from './textures/materials/login_icn/icn_member.png';
import Footer from './components/main_pages/Footer';
import SettingsPageGeofencing from './components/main_pages/settings_page_components/SettingsPageGeofencing';
import SettingsPageSystemConfig from './components/main_pages/settings_page_components/SettingsPageSystemConfig';
import PortalPage from './components/main_pages/PortalPage';
import * as serviceWorker from './serviceWorker';
import { FaBell } from 'react-icons/fa';

const NOTIFICATION_INTERVAL = 3; // in seconds
// const TICK_INTERVAL = 1000;
const rootPath = process.env.PUBLIC_URL;
function NaviMenu(props) {
  const location = useLocation();

  useEffect(() => {
    const isEventPage = location.pathname.includes('/event');

    const handleResize = () => {
      if (window.innerWidth <= 1281) {
        document.body.style.overflowX = 'auto';
        console.log('resize');
      } else {
        document.body.style.overflowX = isEventPage ? 'auto' : 'hidden';
      }
    };

    window.addEventListener('resize', handleResize);
  }, [location.pathname]);

  const [prompt, setPrompt] = useState(false);
  const { accountName, handleLogout, isExpanded } = props;
  const handlePrompt = () => setPrompt(true);
  const handlePromptClose = () => setPrompt(false);
  const handle_logout_button_onclick = () => handleLogout();
  const isLogin = GetLoginInfo() && GetLoginInfo().account;
  const url = FleetFormalAPIUrl.substring(0, FleetFormalAPIUrl.length - 1);

  return (
    <>
      {isLogin && (
        <div className="navbar account-menu-wrapper">
          <button className="navbar notification-button">
            <FaBell aria-hidden="true" />
            {' '}
            {/* 使用 React Icons 中的 Font Awesome bell 图标 */}
          </button>
          <Dropdown className="navbar account-menu">
            <img className="navbar account-logo" alt="" src={MemberImg} />
            <Dropdown.Toggle as="button" className={`navbar account-name ${isExpanded ? 'expanded' : ''}`}>
              {accountName[0].toUpperCase()}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item key="logout" onClick={handlePrompt} disable={isLogin} eventKey="logout">
                {i18n.t('button_logout')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      <Modal show={prompt} centered dialogClassName="logout-modal">
        <Modal.Body onClick={handlePromptClose}>
          <Modal.Title>{i18n.t('logout_warning')}</Modal.Title>
        </Modal.Body>
        <Modal.Footer>
          <Button className="no-button" variant="secondary" onClick={handlePromptClose}>
            {i18n.t('button_no')}
          </Button>
          <Button
            className="yes-button"
            variant="success"
            onClick={handle_logout_button_onclick}
          >
            {i18n.t('button_yes')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

NaviMenu.propTypes = {
  accountName: PropTypes.arrayOf(PropTypes.string),
  handleLogout: PropTypes.func,
  isExpanded: PropTypes.bool,
};

NaviMenu.defaultProps = {
  accountName: [],
  handleLogout: () => { },
  isExpanded: true,
};
class App extends React.Component {
  constructor(props) {
    super(props);
    this.loggedInUser = localStorage.getItem('user');
    if (this.loggedInUser) {
      SetLoginInfo(JSON.parse(localStorage.getItem('loginInfo')));
      if (GetLoginInfo() && GetLoginInfo().account) {
        this.state = {
          loginStatus: true,
          showModal: false,
          loginAccount: GetLoginInfo().account,
        };
      } else {
        this.state = {
          loginStatus: false,
          showModal: false,
          loginAccount: '',
        };
      }
    } else {
      this.state = {
        loginStatus: false,
        showModal: false,
        loginAccount: '',
      };
    }

    this.events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    this.routes = [];
    this.onLogout = this.onLogout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.modalOnHide = this.modalOnHide.bind(this);
    this.addListener();
    this.notificationEvent = [];
    this.controller = new AbortController();
    this.timeCounter = 0;
  }

  componentDidMount() {
    this.routes = Routes;
    if (window.innerWidth <= 1281) {
      document.body.style.overflowX = 'auto';
      console.log('resize');
    }
    // this.interval = setInterval(() => this.tick(), TICK_INTERVAL);
  }

  componentWillUnmount() {
    // window.clearInterval(this.interval);
    for (let i = 0; i < this.events.length; i += 1) {
      window.removeEventListener(this.events[i]);
    }
  }

  componentDidUpdate() {
    if (window.innerWidth <= 1281) {
      document.body.style.overflowX = 'auto';
      console.log('resize');
    }
  }

  handleLogout() {
    const temp = {
      account: 'Logged Out',
      userId: '',
      loginStatus: false,
    };
    SetLoginInfo(temp);
    this.setState({
      loginStatus: GetLoginInfo().loginStatus,
    });
    localStorage.clear();
    window.location.reload(false);
  }

  onLogout() {
    // Send a logout request to the API
    const { loginStatus } = this.state;
    if (loginStatus === true) {
      this.setLogout();
    }
  }

  setLogout() {
    const temp = {
      account: 'Logged Out',
      userId: '',
      loginStatus: false,
    };
    SetLoginInfo(temp);
    this.modalOnShow();
    this.setState({
      loginStatus: GetLoginInfo().loginStatus,
    });
  }

  modalOnShow = () => {
    this.setState({ showModal: true });
  };

  modalOnHide = () => {
    this.setState({ showModal: false });
  };

  resetTimeout() {
  }

  addListener() {
    for (let i = 0; i < this.events.length; i += 1) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
  }

  checkLoginStatus() {
    const { loginStatus } = this.state;
    if (GetLoginInfo().loginStatus === true && loginStatus === false) {
      this.resetTimeout();
      this.setState({ loginStatus: GetLoginInfo().loginStatus });
    } else {
      this.setState({ loginAccount: GetLoginInfo().account });
    }
  }

  tick() {
    const { loginStatus } = this.state;
    this.checkLoginStatus();
    // run every 3 seconds, and only if logged in
    if (loginStatus === true) {
      if (this.timeCounter >= NOTIFICATION_INTERVAL - 1) {
        // this.findNotification();
        this.timeCounter = 0;
      } else {
        this.timeCounter += 1;
      }
    }
  }

  udpateName() {
    this.setState({
      loginAccount: GetLoginInfo().account,
      loginStatus: GetLoginInfo().loginStatus,
    });
    this.forceUpdate();
  }

  render() {
    const { loginStatus, showModal, loginAccount } = this.state;
    const accountName = loginAccount.split('@');
    return (
      <div id="fade-in" className="main background-column">
        <div id="fade-in" className="main background-row">
          <BrowserRouter>
            <Switch>
              <>
                <Route
                  exact
                  path={`${rootPath}/login`}
                  render={(props) => (
                    <LoginPage onUpdateName={this.udpateName.bind(this)} />
                  )}
                />
                {!(window.location.pathname.split('/')[1] === 'Task') && !(window.location.pathname.split('/')[1] === 'portal') && (
                  <div>
                    <div className="main navibar">
                      <NavigationBar />
                    </div>
                    <div className="main nav-space" />
                  </div>
                )}
                <div className="main main-page">
                  <div className="basic-column">
                    {!(window.location.pathname.split('/')[1] === 'Task') && !(window.location.pathname.split('/')[1] === 'portal') && (
                      <div className="main sub-navibar">
                        <div className="basic-row" style={{ justifyContent: 'space-between' }}>
                          <Route exact path={`${rootPath}/fleet`} component={FleetNavBar} />
                          <Route exact path={`${rootPath}/headline`} component={FleetNavBar} />
                          <Route exact path={`${rootPath}/coach/:driverId`} component={CoachNavBar} />
                          <Route exact path={`${rootPath}/case/:caseId`} component={CoachNavBar} />
                          <Route exact path={`${rootPath}/driver/:driverId`} component={DriverNavBar} />
                          <Route exact path={`${rootPath}/RegisterDriver`} component={DriverNavBar} />
                          <Route exact path={`${rootPath}/driverindicator`} component={DriverNavBar} />
                          <Route exact path={`${rootPath}/vehicle/:deviceId`} component={VehicleNavBar} />
                          <Route exact path={`${rootPath}/vehicle/:deviceId/setting`} component={VehicleNavBar} />
                          <Route exact path={`${rootPath}/vehicleutilization`} component={VehicleNavBar} />
                          <Route exact path={`${rootPath}/fuel`} component={VehicleNavBar} />
                          <Route exact path={`${rootPath}/RegisterDevice`} component={VehicleNavBar} />
                          <Route exact path={`${rootPath}/event`} component={EventNavBar} />
                          <Route exact path={`${rootPath}/dispatch`} component={DispatchNavBar} />
                          <Route exact path={`${rootPath}/addTask`} component={DispatchNavBar} />
                          <Route exact path={`${rootPath}/editTask/:taskId`} component={DispatchNavBar} />
                          <Route exact path={`${rootPath}/settingsnf`} component={SettingsNavBar} />
                          <Route exact path={`${rootPath}/settingsgf`} component={SettingsNavBar} />
                          <Route exact path={`${rootPath}/settingsc`} component={SettingsNavBar} />
                          <Route exact path={`${rootPath}/teststatistic`} component={TestCaseNavBar} />
                          <Route exact path={`${rootPath}/testcase`} component={TestCaseNavBar} />
                          <NaviMenu
                            accountName={accountName}
                            handleLogout={this.handleLogout.bind(this)}
                          />
                        </div>
                      </div>
                    )}
                    <div className="main layout">
                      <Route exact path={`${rootPath}/RegisterDriver`} component={RegisterDriverPage} />
                      <Route exact path={`${rootPath}/RegisterDevice`} component={FleetUnregisterDevicePage} />
                      <Route exact path={`${rootPath}/fleet`} component={FleetPage} />
                      <Route exact path={`${rootPath}/headline`} component={FleetPageHeadline} />
                      <Route exact path={`${rootPath}/driver/:driverId`} component={DriverPage} />
                      <Route exact path={`${rootPath}/driverindicator`} component={DriverPageIndicator} />
                      <Route exact path={`${rootPath}/vehicle/:deviceId`} component={VehiclePage} />
                      <Route exact path={`${rootPath}/vehicle/:deviceId/setting`} component={VehiclePage} />
                      <Route exact path={`${rootPath}/vehicleutilization`} component={VehiclePageUtilization} />
                      {/* <Route exact path={`${rootPath}/fuel`} component={VehiclePageFuel} /> */}
                      <Route exact path={`${rootPath}/event`} component={EventPage} />
                      <Route exact path={`${rootPath}/coach/:driverId`} component={CoachPage} />
                      <Route exact path={`${rootPath}/case/:caseId`} component={CoachPageDetail} />
                      <Route exact path={`${rootPath}/extrainfo`} component={ExtraInfo} />
                      <Route exact path={`${rootPath}/addtask`} component={DispatchPageAddTask} />
                      <Route exact path={`${rootPath}/editTask/:taskId`} component={DispatchPageAddTask} />
                      <Route exact path={`${rootPath}/settingsnf`} component={SettingsPageNotification} />
                      <Route exact path={`${rootPath}/settingsgf`} component={SettingsPageGeofencing} />
                      <Route exact path={`${rootPath}/settingsc`} component={SettingsPageSystemConfig} />
                      <Route exact path={`${rootPath}/dispatch`} component={DispatchPage} />
                      <Route exact path={`${rootPath}/teststatistic`} component={TestStatisticPage} />
                      <Route exact path={`${rootPath}/testcase`} component={TestCasePage} />
                      <Route exact path={`${rootPath}/portal`} component={PortalPage} />
                      <Route exact path={`${rootPath}/tune`} component={TunePage} />
                      {loginStatus === true && window.location.pathname === '/' && <Redirect to="/fleet" />}
                      {loginStatus === true && window.location.pathname === '/driver' && <Redirect to="/driver/0" />}
                      {loginStatus === true && window.location.pathname === '/vehicle' && <Redirect to="/vehicle/0" />}
                      {loginStatus === true && window.location.pathname === '/coach' && <Redirect to="/coach/0" />}
                      {loginStatus === false && window.location.pathname === '/login' && <Redirect to="/fleet" />}
                      {loginStatus === false && window.location.pathname === '/portal' && <Redirect to="/login" />}
                      {/* { renderRoutes(this.routes) } */}
                    </div>
                    {(window.location.pathname.split('/')[1] === 'Task') && (
                      <Route exact path={`${rootPath}/task/:taskId`} component={TaskPage} />
                    )}
                  </div>
                </div>
                {((loginStatus !== true)
                && !(window.location.pathname.split('/')[1] === 'Task'))
                && !(window.location.pathname.split('/')[1] === 'portal')
                && <Redirect to="/login" />}

                {/* Modal for log out popup */}
                <Modal
                  show={showModal}
                  onHide={this.modalOnHide}
                  centered
                  dialogClassName="logout-modal"
                >
                  <Modal.Header bsPrefix="logout-modal-header">
                    <Modal.Title bsPrefix="logout-modal-title">
                      {i18n.t('timeout_modal_title')}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body bsPrefix="logout-modal-body">{i18n.t('timeout_modal_text')}</Modal.Body>
                  <Modal.Footer bsPrefix="logout-modal-footer">
                    <Button
                      bsPrefix="logout-modal-button"
                      variant="secondary"
                      onClick={this.modalOnHide}
                    >
                      {i18n.t('button_close')}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            </Switch>
          </BrowserRouter>

        </div>

      </div>
    );
  }
}

serviceWorker.unregister();

export default App;
