import React from 'react';
import {
  Card, Button, Alert, Collapse,
} from 'react-bootstrap';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { ThemeProvider } from '@mui/material/styles';
import i18n from '../../../i18n';
import { FleetFormalAPIUrl, FleetFaceInfoAPIUrl, FleetDriverAPIUrl } from '../../GlobalDefine';
import '../../css/fleet_page/FleetUnregisterDriverPage.css';
import '../../css/Row.css';
import { GetLoginInfo } from '../../../LoginInfo';
import { FetchAccessToken } from '../../../firebase';
import DriverNameDropdown from '../../DriverNameDropDown';
import EmptyRes from '../../../textures/empty.png';
import { TextfieldTheme } from '../utils/ControlTheme';
import RegisterDriverSideBar from './RegisterDriverSideBar';
// import DriverPageMessageCard from './DriverPageMessageCard';

class FleetUnregisterDriverPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      driverNameCheck: true,
      textFieldContentStyle: {
        // style: { backgroundColor: 'white' },
        maxLength: 100,
      },
      time: props.time,
      imagePath: props.imagePath,
      uploadId: props.uploadId,
      registerComplete: false,
      driverList: [],
      currentDriver: {},
      resultText: '',
      currentIndex: 0,
      isTheSameDriver: false,
      isRegistedDriver: false,
      showSaveToast: false,
      errorMessage: '',
      info: {}, // To store data from RegisterDriverSideBar
    };
    this.driverNameText = '';
    this.managerText = '';
    this.rerender = false;
    this.shallUpdate = false;
    this.updateIsRegistedDriver = false;
    this.controller = new AbortController();
  }

  componentDidMount() {
    this.componentIsMounted = true;
    this.getDriverInfo();
  }

  shouldComponentUpdate(nextProps) {
    const { uploadId } = this.state;
    const result = (nextProps.uploadId !== uploadId || this.rerender) || this.shallUpdate;
    if (nextProps.uploadId !== uploadId) {
      this.getDriverInfo(nextProps.uploadId);
      this.updateIsRegistedDriver = true;
    }
    this.shallUpdate = false;
    return result;
  }

  componentDidUpdate(props) {
    // Desired operations: ex setting state
    if (this.updateIsRegistedDriver) {
      this.setState({
        time: props.time,
        imagePath: props.imagePath,
        uploadId: props.uploadId,
        registerComplete: false,
        resultText: '',
        isRegistedDriver: false,
        isTheSameDriver: false,
      });
    } else {
      this.setState({
        time: props.time,
        imagePath: props.imagePath,
        uploadId: props.uploadId,
        registerComplete: false,
        resultText: '',
      });
    }
    this.updateIsRegistedDriver = false;
    this.rerender = false;
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  getDriverInfo(uploadId) {
    if (this.componentIsMounted) {
      this.controller.abort();
      FetchAccessToken().then((accessToken) => {
        axios
          .get(`${FleetDriverAPIUrl}GetDriverListArrangeByFaceId?uploadId=${uploadId}`, {
            headers: { Authorization: `${accessToken}` },
            signal: this.controller.signal,
          })
          .then((response) => {
            if (this.componentIsMounted === true) {
              this.dataReceive = true;
              const tempList = response.data.data;
              const tmpMap = new Map();
              tempList.forEach((value) => {
                tmpMap.set(value.driver_id, false);
              });
              if (response.data.data.length > 0) {
                const currentDriver = response.data.data[0];
                this.setState({ driverList: response.data.data, currentDriver });
              }
            }
          })
          .catch((e) => {
            console.log('Error', e);
          });
      }).catch((error) => {
        console.log('Token fetch error:', error);
      });
    }
  }

  handleSave = async () => {
    const { uploadId, isTheSameDriver, currentDriver } = this.state;
    const accessToken = await FetchAccessToken();
    const headers = {
      Authorization: `${accessToken}`,
    };

    if (isTheSameDriver === true) {
      const uploadData = {
        driver_id: currentDriver.driver_id,
        driver_name: currentDriver.name,
        manager: currentDriver.manager,
        user_id: GetLoginInfo().userId,
        company_id: GetLoginInfo().companyId,
        upload_id: uploadId,
      };
      axios.post(`${FleetFaceInfoAPIUrl}combineFaceId`, uploadData, { headers }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            registerComplete: true,
            showSaveToast: true,
            resultText: i18n.t('register_driver_success'),
          }, () => {
            this.forceUpdate();
            window.setTimeout(() => {
              this.setState({ showSaveToast: false });
              window.location.reload(true);
            }, 3000);
          });
        } else {
          this.setState({
            registerComplete: false,
            showSaveToast: true,
            resultText: response.data.data,
          }, () => {
            this.forceUpdate();
            window.setTimeout(() => {
              this.setState({ showSaveToast: false });
              window.location.reload(true);
            }, 3000);
          });
        }
      });
    } else {
      let valid = true;
      if (!this.driverNameText || this.driverNameText === '') {
        this.rerender = true;
        this.setState({ driverNameCheck: false });
        valid = false;
      }
      if (!valid) {
        return;
      }
      const uploadData = {
        driver_id: uuidv4(),
        driver_name: this.driverNameText,
        manager: this.managerText,
        user_id: GetLoginInfo().userId,
        company_id: GetLoginInfo().companyId,
        upload_id: uploadId,
      };
      axios.post(`${FleetFaceInfoAPIUrl}registerFaceId`, uploadData, { headers }).then((response) => {
        // Success response handling remains the same
        if (response.data.success === true) {
          this.setState({
            registerComplete: true,
            showSaveToast: true,
            resultText: i18n.t('register_driver_success'),
          }, () => {
            this.forceUpdate();
            window.setTimeout(() => {
              this.setState({ showSaveToast: false });
              window.location.reload(true);
            }, 3000);
          });
        } else {
          this.setState({
            registerComplete: false,
            showSaveToast: true,
            resultText: response.data.data,
          }, () => {
            this.forceUpdate();
            window.setTimeout(() => {
              this.setState({ showSaveToast: false });
              window.location.reload(true);
            }, 3000);
          });
        }
      }).catch((error) => {
        // Error handling
        if (error.response && error.response.status === 400) {
          // Handle 400 error specifically
          this.setState({
            registerComplete: false,
            showSaveToast: true,
            // resultText: error.response.data.data, // Customize this message as needed
            errorMessage: error.response.data.code === 103 ? i18n.t('driver_name_duplicated') : error.response.data.data,
          }, () => {
            this.forceUpdate();
            window.setTimeout(() => {
              this.setState({ showSaveToast: false, errorMessage: '' });
              // You might not want to reload the page automatically in case of an error
              // Consider allowing the user to read the error message and take action
            }, 3000);
          });
        } else {
          // Handle other errors
          this.setState({
            registerComplete: false,
            showSaveToast: true,
            resultText: i18n.t('unknown_error'), // A generic error message
          }, () => {
            this.forceUpdate();
            window.setTimeout(() => {
              this.setState({ showSaveToast: false });
              // As above, consider not reloading the page automatically
            }, 3000);
          });
        }
      });
    }
  };

  handleDelete = () => {
    const { uploadId } = this.state;

    FetchAccessToken().then((accessToken) => {
      axios.delete(`${FleetFaceInfoAPIUrl}deleteFaceId/${uploadId}`, {
        headers: {
          Authorization: `${accessToken}`,
        },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            registerComplete: true,
            resultText:              i18n.t('register_driver_delete_success'),
          });
        } else {
          this.setState({
            registerComplete: false,
            resultText: response.data.data,
          });
        }

        // Only reload the page after the state has been set and the response processed
        window.location.reload(true);
      }).catch((error) => {
        console.error('There was an error:', error);
        this.setState({
          registerComplete: false,
          resultText: error.message,
        });
      });
    }).catch((error) => {
      console.error('Error fetching access token:', error);
    });
  };

  handleNameChange = (event) => {
    this.driverNameText = event.target.value;
    this.rerender = true;
    if (!this.driverNameText || this.driverNameText === '') {
      this.setState({ driverNameCheck: false });
    } else {
      this.setState({ driverNameCheck: true });
    }
  };

  handleManagerChange = (event) => {
    this.managerText = event.target.value;
  };

  goLeft = () => {
    const { currentIndex, driverList } = this.state;
    if (driverList.length === 0) {
      return;
    }
    let index;
    if (currentIndex !== 0) {
      index = currentIndex - 1;
    } else {
      index = driverList.length - 1;
    }
    this.shallUpdate = true;
    this.setState({ currentIndex: index, currentDriver: driverList[index] });
  };

  driverSelectCallback = (driver) => {
    this.setState(
      {
        currentDriver: {
          driver_id: driver.split('@')[0], name: driver.split('@')[1], image_path: driver.split('@')[2],
        },
      },
    );
    this.shallUpdate = true;
  };

  goRight = () => {
    const { currentIndex, driverList } = this.state;
    if (driverList.length === 0) {
      return;
    }
    let index;
    if (currentIndex !== driverList.length - 1) {
      index = currentIndex + 1;
    } else {
      index = 0;
    }
    this.shallUpdate = true;
    this.setState({ currentIndex: index, currentDriver: driverList[index] });
  };

  handleDuplicate = (value) => {
    this.shallUpdate = true;
    this.setState({ isTheSameDriver: value });
  };

  handleIsRegistedButton = () => {
    const { isRegistedDriver } = this.state;
    if (isRegistedDriver) {
      this.setState({ isRegistedDriver: false, isTheSameDriver: false });
    } else {
      this.setState({ isRegistedDriver: true, isTheSameDriver: true });
    }
    this.shallUpdate = true;
  };

  onSidebarUnregisterDriverCallback = (info) => {
    console.log(info);
    this.setState({
      device_id: info.device_id,
      imagePath: info.image_path, // Update imagePath from the callback
      time: info.time,
      upload_id: info.upload_id,
      info: info, // Store 'info' in the state
    });
  };

  render() {
    const {
      time, imagePath, registerComplete, resultText, driverNameCheck,
      driverList, currentDriver, isRegistedDriver, uploadId, showSaveToast, errorMessage,
    } = this.state;

    const finalImagePath = FleetFormalAPIUrl.substring(0, FleetFormalAPIUrl.length - 1) + imagePath;
    const { textFieldContentStyle } = this.state;
    const url = FleetFormalAPIUrl.substring(0, FleetFormalAPIUrl.length - 1);
    let src = '';
    if (driverList.length > 0) {
      src = `${url}${currentDriver.image_path}`;
    }
    return (
      <div class="scrollable-container-register">
      <div className="fleet-unregister-driver container">
        <div className="fleet-addtask toast-container">
          <Collapse in={showSaveToast}>
            <div className="collapse alert">
              <Alert key="save" variant={registerComplete ? 'success' : 'warning'} className="collapse alert-style">
                {i18n.t(registerComplete ? 'save_successfully' : errorMessage)}
              </Alert>
            </div>
          </Collapse>
        </div>
        {(uploadId !== '') && (
          <>
            <div className="basic-row">
              <div className="fleet-unregister-driver title">
                {i18n.t('uknown_driver_detected')}
              </div>
              <div className="right-align-container">
                  {registerComplete === false && (
                    <Button className="delete-button" variant="danger" onClick={this.handleDelete.bind(this)}>
                      {i18n.t('button_delete')}
                    </Button>
                  )}
                  {registerComplete === false && (
                    <Button className="save-button" variant="success" onClick={this.handleSave.bind(this)}>
                      {i18n.t('button_save')}
                    </Button>
                  )}
              </div>
            </div>
            <div className="fleet-unregister-driver info">{moment(time).format('MM/DD LT')}</div>
            <div>
              {isRegistedDriver && (
                <div className="fleet-unregister-driver info-row">
                  <div className="fleet-unregister-driver info-column">
                    <Card.Img src={finalImagePath} className="fleet-unregister-driver image" />
                    {!isRegistedDriver && (
                      <>
                        <div className="fleet-unregister-driver isRegistedBlock">
                          <Button
                            variant="primary"
                            onClick={this.handleIsRegistedButton}
                          >
                            {i18n.t('is_this_driver_registered')}
                          </Button>
                        </div>
                        <ThemeProvider theme={TextfieldTheme}>
                          <TextField
                            className="fleet-unregister-device name-field"
                            label={i18n.t('info_driver_name')}
                            id="driver_name"
                            inputProps={textFieldContentStyle}
                            onChange={this.handleNameChange}
                            fullWidth
                            size="small"
                          />
                        </ThemeProvider>
                        <Collapse in={driverNameCheck === false}>
                          <div>
                            <Alert key="danger" variant="danger">
                              {i18n.t('driver_name_empty_warning')}
                            </Alert>
                          </div>
                        </Collapse>
                        <ThemeProvider theme={TextfieldTheme}>
                          <TextField
                            className="fleet-unregister-device name-field"
                            label={i18n.t('info_manager')}
                            id="manager"
                            inputProps={textFieldContentStyle}
                            onChange={this.handleManagerChange}
                            fullWidth
                            size="small"
                          />
                        </ThemeProvider>
                      </>
                    )}
                  </div>
                  <div className="fleet-unregister-driver info-column">
                    {src === '' && (
                      <div className="fleet-unregister-driver match-row">
                        <div className="fleet-unregister-driver name">
                          {i18n.t('no_matching_driver')}
                        </div>
                      </div>
                    )}
                    {src !== '' && (
                      <Card.Img src={src} className="fleet-unregister-driver image" />
                    )}
                    <br/>
                    <div className="fleet-unregister-driver search-description">
                      {i18n.t('please_select_driver')}
                    </div>
                    <div className="fleet-unregister-driver search-row">
                      <DriverNameDropdown
                        driverName={currentDriver.name}
                        driverList={driverList}
                        callbackFunction={this.driverSelectCallback.bind(this)}
                      />
                    </div>
                    <div className="fleet-unregister-driver name">{resultText}</div>
                    <div className="fleet-unregister-driver isRegistedBlock">
                      <Button
                        variant="primary"
                        onClick={this.handleIsRegistedButton}
                      >
                        {i18n.t('is_he_new_driver')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {!isRegistedDriver && (
                <div className="fleet-unregister-driver info-row">
                  <div className="fleet-unregister-driver info-column">
                    <Card.Img src={finalImagePath} className="fleet-unregister-driver image" />
                    {!isRegistedDriver && (
                      <>
                        <br/>
                        <ThemeProvider theme={TextfieldTheme}>
                          <TextField
                            className="fleet-unregister-device name-field"
                            label={i18n.t('info_driver_name')}
                            id="driver_name"
                            inputProps={textFieldContentStyle}
                            onChange={this.handleNameChange}
                            fullWidth
                            size="small"
                          />
                        </ThemeProvider>
                        <Collapse in={driverNameCheck === false}>
                          <div>
                            <Alert key="danger" variant="danger">
                              {i18n.t('driver_name_empty_warning')}
                            </Alert>
                          </div>
                        </Collapse>
                        <ThemeProvider theme={TextfieldTheme}>
                          <TextField
                            className="fleet-unregister-device name-field"
                            label={i18n.t('info_manager')}
                            id="manager"
                            inputProps={textFieldContentStyle}
                            onChange={this.handleManagerChange}
                            fullWidth
                            size="small"
                          />
                        </ThemeProvider>
                        <div className="fleet-unregister-driver isRegistedBlock">
                          <Button
                            variant="primary"
                            onClick={this.handleIsRegistedButton}
                          >
                            {i18n.t('is_this_driver_registered')}
                          </Button>
                        </div>

                      </>
                    )}
                  </div>
                  <div className="fleet-unregister-driver info-column">
                    <div className="fleet-unregister-driver name">{resultText}</div>
                  </div>
                </div>
              )}
            </div>
            {/* Place RegisterDriverSideBar here or where you need it */}
          </>
        )}
        {(uploadId === '') && (
          <div>
            <div className="main empty" style={{marginTop:'20%'}}>
              <img alt="" src={EmptyRes} />
              <Card.Text className="main empty-text">{i18n.t('empty_unknow_driver')}</Card.Text>
            </div>
          </div>
        )}
      </div>
      </div>
    );
  }
}

FleetUnregisterDriverPage.propTypes = {
  time: PropTypes.string,
  imagePath: PropTypes.string,
  uploadId: PropTypes.string,
};

FleetUnregisterDriverPage.defaultProps = {
  time: '',
  imagePath: '',
  uploadId: '',
};

export default FleetUnregisterDriverPage;