import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditRes from '@/textures/editing.png';
import i18n from '@/i18n';
import '@/components/css/vehicle_page/VehiclePageInfo.css';
import '@/components/css/Scrollable.css';

import DatePickerComponent from './DatePickerComponent';

const VehicleDetails = ({
  device,
  dateStart,
  setStartDate,
  addDate,
  dutyDateList,
  onSidebarEditSwitchCallback,
}) => {
  // Define the edit function
  const edit = () => {
    onSidebarEditSwitchCallback(true);
  };

  return (
    <div className="basic-column left-control-margin">
      <div className="basic-row">
        <div className="vehicle-page-info name">{device.car_num}</div>

        {/* Edit button */}
        <button
          type="button"
          className="vehicle-page-info edit"
          onClick={edit} // Attach the edit function here
        >
          <img src={EditRes} alt="Edit" />
        </button>
      </div>

      <div className="vehicle-page-info manager">
        {`${i18n.t('info_production_date')}: ${moment(device.production_date).format('YYYY')}`}
      </div>

      <DatePickerComponent
        dateStart={dateStart}
        setStartDate={setStartDate}
        addDate={addDate}
        dutyDateList={dutyDateList}
      />
    </div>
  );
};

VehicleDetails.propTypes = {
  device: PropTypes.shape({
    car_num: PropTypes.string.isRequired,
    production_date: PropTypes.string.isRequired,
  }).isRequired,
  dateStart: PropTypes.string,
  setStartDate: PropTypes.func.isRequired,
  addDate: PropTypes.func,
  dutyDateList: PropTypes.array,
  onSidebarEditSwitchCallback: PropTypes.func.isRequired,
};

export default VehicleDetails;

