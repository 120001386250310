import axios from 'axios';
import { FleetDeviceAPIUrl, FleetDriverAPIUrl } from '../../../../GlobalDefine';
import { GetLoginInfo } from '../../../../../LoginInfo';

export const fetchDriverInfo = async (driverId) => {
  const { userId } = GetLoginInfo();

  const response = await axios.get(
    `${FleetDriverAPIUrl}findAllDriver`,
    { params: { userId } },
  );

  const driversData = Array.isArray(response.data) ? response.data : response.data.data;
  return driversData.find((driver) => driver.driver_id === driverId) || null;
};

export const fetchDeviceInfo = async (deviceId) => {
  const { userId } = GetLoginInfo();

  const response = await axios.get(
    `${FleetDeviceAPIUrl}findAllDevice`,
    { params: { userId } },
  );

  const devicesData = Array.isArray(response.data) ? response.data : response.data.data;
  return devicesData.find((device) => device.device_id === deviceId) || null;
};

// 新增的函數：獲取駕駛利用率
export const fetchDriverUtilization = async (driverId) => {
  const { userId } = GetLoginInfo();

  const response = await axios.get(
    `${FleetDriverAPIUrl}getMonthlyDriverIndicator`,
    {
      params: {
        userId,
        driverId, // 传入 driverId 作为可选参数
      },
    },
  );

  const data = Array.isArray(response.data) ? response.data : response.data.data;
  return data; // 返回完整的利用率数据
};

export const fetchVehicleUtilization = async (vehicleId) => {
  // 获取用户 ID
  const { userId } = GetLoginInfo();

  try {
    // 调用 API 获取车輛利用数据
    const response = await axios.get(
      `${FleetDeviceAPIUrl}getMonthlyVehicleUtilization`,
      {
        params: {
          userId,
        },
      },
    );

    // 解析返回数据
    const data = Array.isArray(response.data) ? response.data : response.data.data;
    // 返回完整的車輛利用率数据
    return data;
  } catch (error) {
    console.error('Error fetching vehicle utilization data:', error);
    throw error;
  }
};
