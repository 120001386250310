import React, { useState } from 'react';
import VehiclePageMap from '../../../VehiclePageMap';
import VehiclePageTimeLine from '../../../VehiclePageTimeLine';
import VehicleUtilization from '@/components/main_pages/fleet_page_components/fleetInfo/component/device/VehicleUtilization';
import useWindowSize from './useWindowSize';

const MapAndTimelineContainer = ({ device, dateStart, onDuty, detailMode }) => {
  const [currentPlayTime, setCurrentPlayTime] = useState(null);
  const isSmallScreen = useWindowSize(); // Use the hook to get screen size

  const handleCurrentPlayTimeChange = (newCurrentPlayTime) => {
    setCurrentPlayTime(newCurrentPlayTime);
  };
  return (
    <div className="vehicle-page-info data-container">
      {/* Block for small screens */}
      {isSmallScreen && (
        <div className="scrollable-container vehicle-page-info">
          <div className="basic-column">
            {detailMode && (
              <VehiclePageMap
                device={device}
                onDuty={onDuty}
                dateStart={dateStart}
                currentPlayTime={currentPlayTime}
                instant_location={false}
              />
            )}
            <br />
            {detailMode && (
              <VehiclePageTimeLine
                deviceId={device.device_id}
                dateStart={dateStart}
                onCurrentPlayTimeChange={handleCurrentPlayTimeChange}
              />
            )}
            <br />
            <VehicleUtilization deviceId={device.device_id} carNum={device.car_num} />
          </div>
        </div>
      )}

      {/* Block for larger screens */}
      {!isSmallScreen && (
        <div className="vehicle-page-info" style={{ display: 'flex', width: '100%' }}>
          <div className="basic-column">
            <div className="scrollable-container vehicle-page-info">
              {detailMode && (
                <VehiclePageTimeLine
                  deviceId={device.device_id}
                  dateStart={dateStart}
                  onCurrentPlayTimeChange={handleCurrentPlayTimeChange}
                />
              )}
              <br />
              <VehicleUtilization deviceId={device.device_id} carNum={device.car_num} />
            </div>
          </div>
          {/* New div wrapping VehiclePageMap */}
          {detailMode && (
            <VehiclePageMap
              device={device}
              onDuty={onDuty}
              dateStart={dateStart}
              currentPlayTime={currentPlayTime}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MapAndTimelineContainer;
