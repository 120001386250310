import React from 'react';
import { ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdPlayArrow, MdPause, MdSkipPrevious, MdSkipNext } from 'react-icons/md';
import moment from 'moment';
import '../css/PlaybackButtonGroup.css';
import i18n from '@/i18n';

const PlaybackButtonGroup = ({ onBackward, onPlayPause, onForward, isPlaying, currentPlayTime }) => {
  // Format currentPlayTime to display hours:minutes:seconds
  const formattedTime = moment(currentPlayTime).format('HH:mm:ss');

  // Define tooltips for buttons without prefixes
  const tooltips = {
    backward: i18n.t('backward'), // Tooltip for backward button
    playPause: isPlaying ? i18n.t('pause') : i18n.t('play'), // Tooltip for play/pause button
    forward: i18n.t('forward'), // Tooltip for forward button
  };

  return (
    <div className="playback-control-container">
      <ButtonGroup className="playback-button-group">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-backward">{tooltips.backward}</Tooltip>}
        >
          <Button className="control-button" onClick={onBackward}>
            <MdSkipPrevious />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-play-pause">{tooltips.playPause}</Tooltip>}
        >
          <Button className="control-button" onClick={onPlayPause}>
            {isPlaying ? <MdPause /> : <MdPlayArrow />}
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-forward">{tooltips.forward}</Tooltip>}
        >
          <Button className="control-button" onClick={onForward}>
            <MdSkipNext />
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
      <div className="current-time-playback control-button">
        {`${i18n.t('time')} : ${formattedTime}`} {/* Dynamically get time label */}
      </div>
    </div>
  );
};

export default PlaybackButtonGroup;
