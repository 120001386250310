// src/components/Legend.js
import React from 'react';
import L from 'leaflet';
import i18n from '@/i18n';
import '@/components/css/vehicle_page/VehiclePageMap.css';

const MapLegend = ({ map }) => {
  React.useEffect(() => {
    if (map) {
      const legend = L.control({ position: 'bottomright' });

      legend.onAdd = function () {
        const div = L.DomUtil.create('div', 'info legend');
        div.innerHTML = `
          <div class="legend-item">
            <div class="start-pin"></div> 
            <span>${i18n.t('start_point')}</span>
          </div>
          <div class="legend-item">
            <div class="end-pin"></div> 
            <span>${i18n.t('end_point')}</span>
          </div>
          <div class="legend-item">
            <div class="moving-pin-legend"></div> 
            <span>${i18n.t('moving_point')}</span>
          </div>
        `;
        return div;
      };

      legend.addTo(map);

      return () => {
        legend.remove();
      };
    }
  }, [map]);

  return null;
};

export default MapLegend;
