// VehiclePageInfo.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import VehicleDetails from './utils/VehiclePageInfo/components/VehicleDetails';

import MapAndTimelineContainer from './utils/VehiclePageInfo/components/MapAndTimelineContainer';
import { FetchAccessToken } from '../../../firebase';
import { FleetDeviceAPIUrl, FleetFormalAPIUrl } from '../../GlobalDefine';
import { GetLoginInfo } from '../../../LoginInfo';
import '@/components/css/vehicle_page/VehiclePageInfo.css';
import '@/components/css/Scrollable.css';

const VehiclePageInfo = (props) => {
  const [detailMode, setDetailMode] = useState(true);
  const [dateStart, setDateStart] = useState(new Date(moment()));
  const [onDutyDate, setOnDutyDate] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1470);
  const { device } = props;
  const controller = new AbortController();
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1470);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const getWorkingDayInfo = async () => {
      controller.abort();
      const accessToken = await FetchAccessToken();
      axios
        .get(
          `${FleetDeviceAPIUrl}findVehicleWorkingDay?deviceId=${device.device_id}&userId=${GetLoginInfo().userId}`,
          { headers: { Authorization: `${accessToken}` }, signal: controller.signal },
        )
        .then((response) => {
          if (response.data.data) {
            setOnDutyDate(response.data.data);
          }
        })
        .catch((e) => {
          console.log('Error', e);
        });
    };
    getWorkingDayInfo();
  }, [device]);

  const addDate = (day) => {
    const shifted = new Date();
    shifted.setTime(dateStart.getTime() + day * 24 * 60 * 60 * 1000);
    setDateStart(shifted);
  };

  const onDuty = moment().diff(moment(device.last_on_duty_date), 'minutes') < 1;

  // Define the image source
  const url = FleetFormalAPIUrl.substring(0, FleetFormalAPIUrl.length - 1);
  const src = `${url}${device.image_path}`;

  return (
    <div className="vehicle-page-info container">
      <div className="vehicle-page-info overview-container">
        {/* Display the vehicle image */}
        <img
          className="vehicle-page-info image-column"
          alt="vehicle"
          src={src}
          onError={(e) => {
            e.target.onerror = null; // Prevent looping
            e.target.src = `${url}/image/vehicle/default_vehicle.png`; // Default image on error
          }}
        />
        <VehicleDetails
          device={device}
          dateStart={dateStart}
          setStartDate={setDateStart}
          addDate={addDate}
          dutyDateList={onDutyDate} // Assuming you want to highlight the duty dates here
          calendarContainer={null}
          onSidebarEditSwitchCallback={props.onSidebarEditSwitchCallback}
        />

      </div>

      <div className="driver-page-info data-container">
        <MapAndTimelineContainer
          device={device}
          dateStart={dateStart}
          onDuty={onDuty}
          isSmallScreen={isSmallScreen}
          detailMode={detailMode}
        />
      </div>
    </div>
  );
};

VehiclePageInfo.propTypes = {
  device: PropTypes.shape({
    device_id: PropTypes.string.isRequired,
    image_path: PropTypes.string.isRequired,
    last_on_duty_date: PropTypes.string.isRequired,
  }).isRequired,
  onSidebarEditSwitchCallback: PropTypes.func,
};

VehiclePageInfo.defaultProps = {
};

export default VehiclePageInfo;

