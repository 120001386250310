import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Tooltip as MuiTooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { GetLoginInfo } from '../../../LoginInfo';
import { FetchAccessToken } from '../../../firebase';
import { FleetToolAPIUrl } from '../../GlobalDefine';
import Question_mark from '../../../textures/question.png';
import '../../css/settings_page/TunePage.css'; // Make sure the stylesheet is imported

const initialSettings = [
  {
    id: 1, type: 0, name: 'CollisionThs', file_name: '/config/maihal.ini', description: 'Threshold level for detecting collisions, measured in milli-g (mg), which is one-thousandth of the gravitational force unit, g.', defaultValue: 1140,
  },
  {
    id: 2, type: 0, name: 'CollisionDur', file_name: '/config/maihal.ini', description: 'Collision is detected when the number of frames exceeding the specified threshold surpasses this value.', defaultValue: 3,
  },
  {
    id: 3, type: 0, name: 'HarshAccThs', file_name: '/config/maihal.ini', description: 'Threshold level for detecting harsh acc, measured in milli-g (mg), which is one-thousandth of the gravitational force unit, g.', defaultValue: 200,
  },
  {
    id: 4, type: 0, name: 'HarshAccDur', file_name: '/config/maihal.ini', description: 'Harsh acc is detected when the number of frames exceeding the specified threshold surpasses this value.', defaultValue: 26,
  },
  {
    id: 5, type: 0, name: 'HarshBrkThs', file_name: '/config/maihal.ini', description: 'Threshold level for detecting harsh brake, measured in milli-g (mg), which is one-thousandth of the gravitational force unit, g.', defaultValue: 400,
  },
  {
    id: 6, type: 0, name: 'HarshBrkDur', file_name: '/config/maihal.ini', description: 'Harsh braking is detected when the number of frames exceeding the specified threshold surpasses this value.', defaultValue: 5,
  },
  {
    id: 7, type: 0, name: 'HarshTurnThs', file_name: '/config/maihal.ini', description: 'Threshold for harsh turning detection in m/s^2', defaultValue: 4.9,
  },
  {
    id: 8, type: 0, name: 'HarshTurnMinDur', file_name: '/config/maihal.ini', description: 'Harsh turning is detected when the number of frames exceeding the specified threshold surpasses this value.', defaultValue: 5,
  },
  {
    id: 11, type: 0, name: 'SigmaThs', file_name: '/config/maihal.ini', description: 'Threshold for stop detection on acceleration x-axis in m/s^2', defaultValue: 0.125,
  },
  {
    id: 12, type: 0, name: 'SigmaThs2', file_name: '/config/maihal.ini', description: 'Threshold for stop detection on gyro y-axis (pitch) in milli-degree per second', defaultValue: 750,
  },
  {
    id: 13, type: 1, name: 'dms_closed_eye_threshold', file_name: '/customer/dms/config/dms_omni.yml', description: 'Per-frame threshold to determine a valid closed eyes, higher is more sensitive.', defaultValue: 0.15,
  },
  {
    id: 14, type: 1, name: 'dms_smoking_threshold', file_name: '/customer/dms/config/dms_omni.yml', description: 'Per-frame thresholds used to determine smoking behavior, lower is more sensitive.', defaultValue: 0.7,
  },
  {
    id: 15, type: 1, name: 'dms_phone_calling_threshold', file_name: '/customer/dms/config/dms_omni.yml', description: 'Per-frame thresholds used to determine phone-calling behavior, lower is more sensitive.', defaultValue: 0.8,
  },
];

const TunePage = () => {
  const [settings, setSettings] = useState(initialSettings);

  const updateSettingsWithFetchedConfigs = (fetchedConfigs) => {
    const updatedSettings = settings.map((setting) => {
      const fetchedConfig = fetchedConfigs.find((config) => config.key === setting.name);
      return fetchedConfig ? { ...setting, currentValue: fetchedConfig.value } : setting;
    });
    setSettings(updatedSettings);
  };

  const fetchDeviceConfigs = async () => {
    try {
      const accessToken = await FetchAccessToken();
      const company_id = GetLoginInfo().companyId;
      const response = await axios.get(`${FleetToolAPIUrl}getDeviceConfigs?companyId=${company_id}`, {
        headers: { Authorization: accessToken },
      });

      const fetchedConfigs = response.data;
      updateSettingsWithFetchedConfigs(fetchedConfigs);
    } catch (error) {
      console.error('Failed to fetch device configurations:', error);
    }
  };

  useEffect(() => {
    fetchDeviceConfigs();
  }, []);

  const handleChange = (id, value) => {
    const newSettings = settings.map((setting) => (setting.id === id ? { ...setting, currentValue: value } : setting));
    setSettings(newSettings);
  };

  const handleSave = async (setting) => {
    console.log('Attempting to save setting:', setting);
    FetchAccessToken().then(async (accessToken) => {
      try {
        const company_id = GetLoginInfo().companyId;
        const response = await axios.post(`${FleetToolAPIUrl}updateDeviceConfig/${company_id}`, {
          key: setting.name, // Assuming setting object contains key
          type: setting.type, // Assuming setting object contains type
          value: setting.currentValue, // Assuming setting object contains value
          file_name: setting.file_name, // Assuming setting object contains file_name
        }, {
          headers: {
            Authorization: accessToken,
          },
        });

        console.log('Success:', response.data);
        alert('Device configuration saved successfully!');
      } catch (error) {
        console.error('Error saving device configuration:', error);
        alert(`Failed to save device configuration: ${error.message}`);
      }
    }).catch((error) => {
      console.error('Error fetching access token:', error);
      // Handle the token fetching error appropriately
    });
  };

  return (
    <>
      <div className="settings-introduction">
        <p>
          This page helps you set the OMNI device. Please press the save button to update the
          parameter and upload it to the cloud. The OMNI device will apply this change after
          the next reboot and connection to the internet. If the configuration is related to
          DMS, reboot again.
        </p>
      </div>
      <table>
        <thead>
          <tr>
            <th>Setting Name</th>
            <th>Value</th>
            <th>Default Value</th>
            <th>Save</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {settings.map((setting) => (
            <tr key={setting.id}>
              <td>{setting.name}</td>
              <td>
                <input
                  type="number"
                  value={setting.currentValue || setting.defaultValue}
                  onChange={(e) => handleChange(setting.id, e.target.value)}
                  style={{ color: 'black', padding: '5px' }}
                />
              </td>
              <td>{setting.defaultValue}</td>
              <td>
                <button onClick={() => handleSave(setting)}>Save</button>
              </td>
              <td>{setting.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TunePage;
