/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import RegisterDriverSideBar from './RegisterDriverSideBar';
import FleetUnregisterDriverPage from './FleetUnregisterDriverPage';

const RegisterDriverPage = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    device_id: '',
    image_path: '',
    time: '',
    upload_id: ''
  });

  const onSidebarUnregisterDriverCallback = (info) => {
    setDeviceInfo({
      device_id: info.device_id,
      image_path: info.image_path,
      time: info.time,
      upload_id: info.upload_id
    });
  };

  useEffect(() => {
    // componentDidMount equivalent
    let componentIsMounted = true;

    return () => {
      // componentWillUnmount equivalent
      componentIsMounted = false;
    };
  }, []);

  const {
    device_id, image_path, time, upload_id
  } = deviceInfo;

  return (
    <div className="basic-row">
      <div className='main sidebar driver' style={{background:'transparent'}}>
        <RegisterDriverSideBar
          sidebarUnregisterDriverCallback={onSidebarUnregisterDriverCallback}
        />
      </div>
      <div className="main content">
        <FleetUnregisterDriverPage
          deviceId={device_id}
          imagePath={image_path}
          time={time}
          uploadId={upload_id}
        />
      </div>
    </div>
  );
};

export default RegisterDriverPage;
