import FleetPage from './components/main_pages/FleetPage';
import DriverPage from './components/main_pages/DriverPage';
import VehiclePage from './components/main_pages/VehiclePage';
import EventPage from './components/main_pages/EventPage';
import CoachPage from './components/main_pages/CoachPage';
import CoachPageDetail from './components/main_pages/coach_page_components/CoachPageDetail';
import LoginPage from './components/main_pages/LoginPage';
import ExtraInfo from './components/ExtraInfo';
import TaskPage from './components/main_pages/TaskPage';

const rootPath = process.env.PUBLIC_URL;
const Routes = [
  {
    path: `${rootPath}/fleet`,
    component: FleetPage,
    breadcrumbName: 'FLEET',
  },
  {
    path: `${rootPath}/driver/:driverId`,
    component: DriverPage,
    breadcrumbName: 'DRIVERID',
  },

  {
    path: `${rootPath}/driver`,
    component: DriverPage,
    breadcrumbName: 'DRIVER',
  },
  {
    path: `${rootPath}/vehicle/:deviceId`,
    component: VehiclePage,
    breadcrumbName: 'VEHICLEID',
  },
  {
    path: `${rootPath}/vehicle`,
    component: VehiclePage,
    breadcrumbName: 'VEHICLE',
  },
  {
    path: `${rootPath}/event`,
    component: EventPage,
    breadcrumbName: 'EVENT',
  },
  {
    path: `${rootPath}/coach/:driverId`,
    component: CoachPage,
    breadcrumbName: 'COACH',
  },
  {
    path: `${rootPath}/case/:caseId`,
    component: CoachPageDetail,
    breadcrumbName: 'CASE',
  },
  {
    path: `${rootPath}/login`,
    component: LoginPage,
    breadcrumbName: 'LOGIN',
  },
  {
    path: `${rootPath}/extrainfo`,
    component: ExtraInfo,
    breadcrumbName: 'EXTRAINFO',
  },
  {
    path: `${rootPath}/task/:testId`,
    component: TaskPage,
    breadcrumbName: 'TASK',
  },
];

// const LoginRoute = [
//   {
//     path: `${rootPath}/login`,
//     component: LoginPage,
//     breadcrumbName: 'LOGIN',
//   },
// ];

export { Routes };
