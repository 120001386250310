import React from 'react';
import i18n from 'i18next'; // 確保引入 i18n
import { useTranslation } from 'react-i18next';
import { BarChartComponent } from '../../chart/chartFunction';
import '../../../../../css/common/Description.css';

const DriverUtilizationChart = ({
  currentSumEngine, currentSumIdle, avgEngine, avgIdle, driverName,
}) => {
  const totalDuration = currentSumEngine + currentSumIdle;
  const enginePercentage = totalDuration > 0 ? ((currentSumEngine / totalDuration) * 100).toFixed(1) : 0;
  const idlePercentage = totalDuration > 0 ? ((currentSumIdle / totalDuration) * 100).toFixed(1) : 0;
  const { t } = useTranslation();
  return (

    <div style={{
      marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center',
    }}
    >
      <div style={{
        marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center',
      }}
      >

        {/* <div className='description-content'>
          <p>{t('pie_chart_statistics_description')}</p>
        </div> */}
        {/* <div className='description-content'>
              <p>{t('working_status')}</p>
        </div> */}
        {/* <div style={{ width: '400px', height: '200px', paddingBottom: '20px' }}>

        <PieChartComponent
          engineDuration={currentSumEngine}
          idleDuration={currentSumIdle}
          enginePercentage={enginePercentage}
          idlePercentage={idlePercentage}
          colors={['#0000FF', '#008000']}
        />
      </div> */}
      </div>
      <div className="description-content">
        <p>{t('engine_time_driver_statistics_description')}</p>
      </div>
      <div className="bar-head-title-style">
        <p style={{ color: 'white' }}>{t('engine_duration_comparison')}</p>
      </div>
      <div style={{ width: '400px', height: '300px', marginTop: '20px' }}>
        <BarChartComponent
          label={driverName}
          metricName={i18n.t('engine_duration')} // 使用翻譯的文本
          specificValue={currentSumEngine}
          averageValue={avgEngine}
          color="#0000FF"
          options={{
            plugins: {
              legend: {
                labels: {
                  color: 'white', // Set legend label color to white
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  color: 'white', // X-axis label color
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)', // X-axis grid line color
                },
              },
              y: {
                ticks: {
                  color: 'white', // Y-axis label color
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)', // Y-axis grid line color
                },
              },
            },
          }}
        />
      </div>
      {/* <div className='description-content'>
          <p>{t('idle_statistics_description')}</p>
        </div>
      <div style={{ width: '400px', height: '300px', marginTop: '20px' }}>
        <BarChartComponent
          label={driverName}
          metricName={i18n.t('idle_duration')} // 使用翻譯的文本
          specificValue={currentSumIdle}
          averageValue={avgIdle}
          color="#008000"
          options={{
            plugins: {
              legend: {
                labels: {
                  color: 'white' // Set legend label color to white
                }
              }
            },
            scales: {
              x: {
                ticks: {
                  color: 'white', // X-axis label color
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)', // X-axis grid line color
                },
              },
              y: {
                ticks: {
                  color: 'white', // Y-axis label color
                },
                grid: {
                  color: 'rgba(255, 255, 255, 0.1)', // Y-axis grid line color
                },
              },
            },
          }}
        />
      </div> */}
    </div>
  );
};

export default DriverUtilizationChart;
