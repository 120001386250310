import { useState, useEffect } from 'react';

const useWindowSize = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1470);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1470);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isSmallScreen;
};

export default useWindowSize;
