import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import FleetPageSideBar from './fleet_page_components/FleetPageSideBar';
import StatisticPageMap from './statistic_page_componenets/StatisticPageMap';
import '../css/MainLayout.css';
import '../css/Scrollable.css';
import StatStatisticPageFleetCard from './statistic_page_componenets/StatisticPageFleetCard';
import { FleetDeviceAPIUrl, GetDemoDate } from '../GlobalDefine';
import { FetchAccessToken } from '../../firebase';
import { GetLoginInfo, IsDemoAccount } from '../../LoginInfo';

function FleetPage() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [currentDevice, setCurrentDevice] = useState({});
  const [fleetStatus, setFleetStatus] = useState([]);
  const [driverLocations, setDriverLocations] = useState([]);

  // Error handling function
  const handleError = (error) => {
    if (error.response) {
      console.log('Error:', error.response.data); // Server response with error message
    } else if (error.request) {
      console.log('Error: No response from the server'); // No response received from the server
    } else {
      console.log('Error:', error.message); // Other errors, like network issues
    }
  };

  // Helper function to determine display time (considering demo account scenario)
  const getDisplayTime = () => {
    if (IsDemoAccount()) {
      const demoTime = GetDemoDate();
      return demoTime.toISOString();
    }
    return new Date().toISOString();
  };

  const getInfo = async () => {
    try {
      // Fetch access token once at the start to reuse it later
      const accessToken = await FetchAccessToken();
      const { userId } = GetLoginInfo();
      const fleetStatusUrl = `${FleetDeviceAPIUrl}fleetCombinedStatus?userId=${userId}`;
      const warningDeviceUrl = `${FleetDeviceAPIUrl}findWarningDevice?time=${getDisplayTime()}&userId=${userId}`;

      // Fetch fleet status
      const fleetResponse = await axios.get(fleetStatusUrl, {
        headers: { Authorization: `${accessToken}` },
      });

      const tempFleetStatus = fleetResponse.data.data || [];

      if (tempFleetStatus.length > 0) {
        setCurrentDevice(tempFleetStatus[0].device_id);
      }

      // Fetch warning devices (idle, collision, geoFencing)
      const warningResponse = await axios.get(warningDeviceUrl, {
        headers: { Authorization: `${accessToken}` },
      });

      const { idle = [], collision = [], geoFencing = [] } = warningResponse.data.data || {};
      const updatedFleetStatus = updateFleetStatusWithWarnings(tempFleetStatus, idle, collision, geoFencing);

      setFleetStatus(updatedFleetStatus);
    } catch (error) {
      handleError(error); // Error handler should handle both fetch and other errors
    }
  };

  // Helper function to update fleet status with warnings
  const updateFleetStatusWithWarnings = (fleetStatus, idle, collision, geoFencing) => fleetStatus.map((device) => {
    const isIdle = idle.some((idleDevice) => idleDevice.device_id === device.device_id);
    const isCollision = collision.some((colDevice) => colDevice.device_id === device.device_id);
    const isGeoFencing = geoFencing.some((gfDevice) => gfDevice.device_id === device.device_id);

    return {
      ...device,
      idle: isIdle,
      collision: isCollision,
      geoFencing: isGeoFencing,
    };
  });

  // Update isSmallScreen on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);

    // Call getInfo on initial load
    getInfo();

    // Set interval to call getInfo periodically every 30 seconds
    const intervalId = setInterval(getInfo, 3000); // 30,00 ms = 3 seconds

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(intervalId); // Clear interval when component unmounts
    };
  }, []); // Empty dependency array ensures this runs only on initialization

  const handleDeviceSelect = (device) => {
    setCurrentDevice(device);
  };

  return (
    <div className="scrollable-container fleet">
      <div className="basic-row" style={{ display: 'flex', width: '100%' }}>
        {isSmallScreen ? (
          // Layout for small screens
          <div className="main sidebar-container">
            <StatStatisticPageFleetCard
              fleetStatus={fleetStatus}
            />
            <div className="main sidebar fleet">
              <FleetPageSideBar
                currentDeviceId={currentDevice.device_id}
                driverLocations={driverLocations}
                onDeviceSelect={handleDeviceSelect}
                fleetStatus={fleetStatus}
              />
            </div>
            <br />
            <StatisticPageMap
              driverLocations={driverLocations}
              currentDevice={currentDevice}
              fleetStatus={fleetStatus}
            />
          </div>
        ) : (
          // Layout for larger screens
          <>
            <div className="main sidebar-container">
              <StatStatisticPageFleetCard
                fleetStatus={fleetStatus}
              />
              <div className="main sidebar fleet">
                <FleetPageSideBar
                  currentDeviceId={currentDevice.device_id}
                  driverLocations={driverLocations}
                  onDeviceSelect={handleDeviceSelect}
                  fleetStatus={fleetStatus}
                />
              </div>
            </div>
            <StatisticPageMap
              driverLocations={driverLocations}
              currentDevice={currentDevice}
              fleetStatus={fleetStatus}
            />
          </>
        )}
      </div>
    </div>
  );
}

FleetPage.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

FleetPage.defaultProps = {
  match: {},
};

export default FleetPage;
