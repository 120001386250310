import { useState, useEffect } from 'react';
import i18n from 'i18next'; // Ensure i18n is imported
import { fetchDriverUtilization } from '../../utils/Api_helper';

const useDriverUtilization = (driverId, driverName) => {
  const [driverUtilizationData, setDriverUtilizationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentSumEngine, setCurrentSumEngine] = useState(0);
  const [currentSumIdle, setCurrentSumIdle] = useState(0);
  const [avgEngine, setAvgEngine] = useState(0);
  const [avgIdle, setAvgIdle] = useState(0);
  const [highLightWorkingStatus, setHighLightWorkingStatus] = useState('');
  const [highLightUtilizationStatus, setHighLightUtilizationStatus] = useState('');
  const [eventStats, setEventStats] = useState({ personal: {}, average: {}, personalMessages: [] });
  const [fetchedDriverName, setFetchedDriverName] = useState('');
  const [hasData, setHasData] = useState(null); // New state for hasData

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await fetchDriverUtilization(driverId);

      if (data && data.length > 0) {
        const matchingDriverData = data.filter((d) => d.driver_id === driverId);
        if (matchingDriverData.length > 0) {
          const name = matchingDriverData[0].driver_name || i18n.t('unknown_driver');
          setFetchedDriverName(name);
          calculateAverages(data);
          // Set hasData to true if the fetched name matches the provided driverName
          setHasData(true);

          const totalEngine = matchingDriverData.reduce((sum, d) => sum + d.engine_duration, 0);
          const totalIdle = matchingDriverData.reduce((sum, d) => sum + d.idle_duration, 0);

          setCurrentSumEngine(totalEngine);
          setCurrentSumIdle(totalIdle);

          // Calculate averages and set them

          const stats = calculateEventStatistics(matchingDriverData, totalEngine, data, name);
          setEventStats(stats);

          // Calculate statuses independently
          updateHighLightWorkingStatus(name, totalIdle, totalEngine);
          updateHighLightUtilizationStatus(name, totalEngine);
        } else {
          setFetchedDriverName(i18n.t('unknown_driver'));
        }
      } else {
        setFetchedDriverName(i18n.t('unknown_driver'));
        setHasData(false); // No data available
      }
      setIsLoading(false);
    };

    fetchData();
  }, [driverId, driverName]); // Add driverName to dependencies

  const calculateAverages = (matchingDriverData) => {
    // 计算特定驾驶员的引擎和闲置时间的总和
    const totalEngine = matchingDriverData.reduce((sum, d) => sum + d.engine_duration, 0);
    const totalIdle = matchingDriverData.reduce((sum, d) => sum + d.idle_duration, 0);

    // 使用 Set 计算唯一驾驶员名称的数量
    const uniqueDriverNames = new Set(matchingDriverData.map((d) => d.driver_id));
    const totalRecords = uniqueDriverNames.size;

    // 计算平均值
    const avgEngineTime = totalRecords > 0 ? (totalEngine) / totalRecords : 0;
    const avgIdleTime = totalRecords > 0 ? totalIdle / totalRecords : 0;

    // 更新状态
    setAvgEngine(avgEngineTime);
    setAvgIdle(avgIdleTime);
  };

  const calculateEventStatistics = (matchingDriverData, totalEngine, data, name) => {
    const personalEventStats = {
      DA: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.DA, 0) / totalEngine * 60) : 0,
      OR: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.OR, 0) / totalEngine * 60) : 0,
      SL: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.SL, 0) / totalEngine * 60) : 0,
      PH: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.PH, 0) / totalEngine * 60) : 0,
      SM: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.SM, 0) / totalEngine * 60) : 0,
      FT: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.FT, 0) / totalEngine * 60) : 0,
      TTC: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.TTC, 0) / totalEngine * 60) : 0,
      LDW: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.LDW, 0) / totalEngine * 60) : 0,
      HMW: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.HMW, 0) / totalEngine * 60) : 0,
      HACC: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.HACC, 0) / totalEngine * 60) : 0,
      HBR: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.HBR, 0) / totalEngine * 60) : 0,
      HTUR: totalEngine > 0 ? (matchingDriverData.reduce((sum, d) => sum + d.HTUR, 0) / totalEngine * 60) : 0,
    };

    const totalEngineHours = data.reduce((sum, d) => sum + d.engine_duration, 0) / 60;
    const allEventStats = {
      DA: data.reduce((sum, d) => sum + d.DA, 0),
      OR: data.reduce((sum, d) => sum + d.OR, 0),
      SL: data.reduce((sum, d) => sum + d.SL, 0),
      PH: data.reduce((sum, d) => sum + d.PH, 0),
      SM: data.reduce((sum, d) => sum + d.SM, 0),
      FT: data.reduce((sum, d) => sum + d.FT, 0),
      TTC: data.reduce((sum, d) => sum + d.TTC, 0),
      LDW: data.reduce((sum, d) => sum + d.LDW, 0),
      HMW: data.reduce((sum, d) => sum + d.HMW, 0),
      HACC: data.reduce((sum, d) => sum + d.HACC, 0),
      HBR: data.reduce((sum, d) => sum + d.HBR, 0),
      HTUR: data.reduce((sum, d) => sum + d.HTUR, 0),
    };

    const averageEventStats = {
      DA: totalEngineHours > 0 ? (allEventStats.DA / totalEngineHours) : 0,
      OR: totalEngineHours > 0 ? (allEventStats.OR / totalEngineHours) : 0,
      SL: totalEngineHours > 0 ? (allEventStats.SL / totalEngineHours) : 0,
      PH: totalEngineHours > 0 ? (allEventStats.PH / totalEngineHours) : 0,
      SM: totalEngineHours > 0 ? (allEventStats.SM / totalEngineHours) : 0,
      FT: totalEngineHours > 0 ? (allEventStats.FT / totalEngineHours) : 0,
      TTC: totalEngineHours > 0 ? (allEventStats.TTC / totalEngineHours) : 0,
      LDW: totalEngineHours > 0 ? (allEventStats.LDW / totalEngineHours) : 0,
      HMW: totalEngineHours > 0 ? (allEventStats.HMW / totalEngineHours) : 0,
      HACC: totalEngineHours > 0 ? (allEventStats.HACC / totalEngineHours) : 0,
      HBR: totalEngineHours > 0 ? (allEventStats.HBR / totalEngineHours) : 0,
      HTUR: totalEngineHours > 0 ? (allEventStats.HTUR / totalEngineHours) : 0,
    };

    const personalMessages = Object.keys(personalEventStats).map((stat) => (personalEventStats[stat] > averageEventStats[stat]
      ? `\n${name} ${stat} ${i18n.t('above_average')}\n`
      : null)).filter((msg) => msg !== null);

    return {
      personal: personalEventStats,
      average: averageEventStats,
      personalMessages,
    };
  };

  // Function to update working status
  const updateHighLightWorkingStatus = (name, totalIdle, totalEngine) => {
    const workingStatusMessage = totalIdle / totalEngine > (avgIdle / avgEngine) * 1.1
      ? `${name}${i18n.t('idle_time_abnormal', { carNum: '' })}\n`
      : '';

    setHighLightWorkingStatus(workingStatusMessage);
  };

  // Function to update utilization status
  const updateHighLightUtilizationStatus = (name, totalEngine) => {
    const utilizationStatusMessage = totalEngine < avgEngine * 0.8
      ? `${name}${i18n.t('utilization_low', { carNum: '' })}\n`
      : '';

    setHighLightUtilizationStatus(utilizationStatusMessage);
  };

  return {
    driverUtilizationData,
    isLoading,
    currentSumEngine,
    currentSumIdle,
    avgEngine,
    avgIdle,
    highLightWorkingStatus,
    highLightUtilizationStatus,
    eventStats,
    fetchedDriverName,
    hasData,
  };
};

export default useDriverUtilization;
