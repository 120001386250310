import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Alert, Collapse, Overlay, Tooltip,
} from 'react-bootstrap';
import axios from 'axios';
import L, { latLng } from 'leaflet';
import { isMobile } from 'react-device-detect';
import { FleetDeviceAPIUrl, FleetGeofenceAPIUrl } from '../../GlobalDefine';
import { GetLoginInfo } from '../../../LoginInfo';
import { FetchAccessToken } from '../../../firebase';
import '../../css/settings_page/SettingsPageGeofencing.css';
import '../../css/Button.css';
import '../../css/Scrollable.css';
import '../../css/ButtonStyle.css';
import i18n from '../../../i18n';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rotatedmarker';
import SettingsButtonGroup from '../utils/SettingsButtonGroup';
import VehicleDropdown from '../../VehicleDropDown';

const FenceType = {
  Rect: '_rect',
  Circle: '_circle',
  Polygon: '_polygon',
};

class SettingsPageGeofencing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minPointsCountCheck: true,
      maxPointsCountCheck: true,
      saveSuccess: false,
      deviceList: [],
      currentDevice: {
        device_id: '',
        car_num: i18n.t('all_vehicles'),
      },
      isDragging: false,
      dragStartPos: null,
      isDragMode: false,

      firstClick: null,
      showTooltip: false,
      tooltipPosition: null,
      markers: [], // 用于存储标记的状态
    };

    this.driver = {
      last_location: [25.0, 121.5],
      driver_id: '',
      name: '',
    };

    this.initCoordinate = [this.driver.last_location[0], this.driver.last_location[1]];
    this.mapCoordinates = [this.initCoordinate];

    // For Icon rotation
    this.lastMarkerLocation = [];
    this.lastMarkerAngle = [];
    this.markerGroup = undefined;
    this.streetMap = L.tileLayer();
    this.darkMap = L.tileLayer();
    this.brightMap = L.tileLayer();
    this.controlLayer = L.control();
    this.componentIsMounted = false;
    this.controller = new AbortController();
    this.vehicleController = new AbortController();
    this.type = FenceType.Rect;
    this.polygonPoints = [];
    this.polygon = null;
    this.rectangle = false;
    this.circle = null;
    this.firstPoint = null;

    // 确保方法绑定到当前实例
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.handleTypeValueChange = this.handleTypeValueChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClear = this.handleClear.bind(this);

    this.clearMarkers = this.clearMarkers.bind(this);
  }

  componentDidMount() {
    this.componentIsMounted = true;
    this.initMap();
    this.initMarkerGroup();
    this.getInfo();
    this.getVehicleInfo();
    this.map.on('mousedown', this.onMouseDown);
    this.map.on('mousemove', this.onMouseMove);
    this.map.on('mouseup', this.onMouseUp);
    this.map.on('touchstart', this.onTouchStart);
    this.map.on('touchmove', this.onTouchMove);
    this.map.on('touchend', this.onTouchEnd);
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
    this.clearRectangle();
    this.clearCircle();
    this.clearPolygon();
    this.clearMarkers();
    this.map.off('mousedown', this.onMouseDown);
    this.map.off('mousemove', this.onMouseMove);
    this.map.off('mouseup', this.onMouseUp);
    this.map.off('touchstart', this.onTouchStart);
    this.map.off('touchmove', this.onTouchMove);
    this.map.off('touchend', this.onTouchEnd);
  }

  onMouseDown(e) {
    const { isDragMode } = this.state;
    if (isDragMode && !isMobile) {
      this.setState({
        isDragging: true,
        dragStartPos: e.latlng,
      });

      if (this.type === FenceType.Rect) {
        this.clearRectangle();
        this.rectangle = L.rectangle([e.latlng, e.latlng], { color: '#30f', weight: 1 }).addTo(this.map);
      } else if (this.type === FenceType.Circle) {
        this.clearCircle();
        this.circle = L.circle(e.latlng, { radius: 0, color: '#30f', weight: 1 }).addTo(this.map);
      }
    } else if (!isDragMode || isMobile) {
      // 非拖曳模式，處理單擊事件
      if (this.type === FenceType.Circle) {
        if (this.state.firstClick) {
          const radius = this.map.distance(this.state.firstClick, e.latlng);
          this.clearCircle();
          this.circle = L.circle(this.state.firstClick, { radius, color: '#30f', weight: 1 }).addTo(this.map);
          this.polygonPoints = [];
          for (let i = 0; i < 360; i += 20) {
            this.polygonPoints.push(this.getPointOnCircle(i));
          }
          this.setState({ firstClick: null, showTooltip: false });
          this.clearMarkers();
        } else {
          this.setState({ firstClick: e.latlng, showTooltip: true, tooltipPosition: e.latlng });
          this.addMarker(e.latlng, 1);
        }
      } else if (this.type === FenceType.Rect) {
        if (this.state.firstClick) {
          this.clearRectangle();
          this.rectangle = L.rectangle([this.state.firstClick, e.latlng], { color: '#30f', weight: 1 }).addTo(this.map);

          this.polygonPoints = [
            this.rectangle.getBounds().getSouthWest(),
            this.rectangle.getBounds().getSouthEast(),
            this.rectangle.getBounds().getNorthEast(),
            this.rectangle.getBounds().getNorthWest(),
          ];
          this.setState({ firstClick: null, showTooltip: false });
          this.clearMarkers();
        } else {
          this.setState({ firstClick: e.latlng, showTooltip: true, tooltipPosition: e.latlng });
          this.addMarker(e.latlng, 1);
        }
      } else if (this.type === FenceType.Polygon) {
        if (!this.state.firstClick) {
          this.polygonPoints = []; // 第一次点击时清空多边形点
          this.polygon = L.polygon(e.latlng, { color: '#30f', weight: 1 }).addTo(this.map);
          this.clearPolygon();
          this.setState({ firstClick: e.latlng, showTooltip: true, tooltipPosition: e.latlng });
          this.addMarker(e.latlng, 1);
          this.polygonPoints.push(e.latlng);
        } else if (this.polygonPoints.length < 20) {
          this.polygonPoints.push(e.latlng);
          if (isDragMode && !isMobile) {
            console.log(this.polygonPoints.length);
            this.addMarker(e.latlng, this.polygonPoints.length);
          } else {
            this.addMarker(e.latlng, this.polygonPoints.length);
          }

          if (!this.polygon) {
            this.polygon = L.polygon([this.polygonPoints], { color: '#30f', weight: 1 }).addTo(this.map);
          } else {
            this.polygon.addLatLng(e.latlng);
          }
          this.setState({ showTooltip: false });
        } else {
          this.setState({ maxPointsCountCheck: false });
        }
      }
    }
  }

  onMouseMove(e) {
    const { isDragging, dragStartPos, isDragMode } = this.state;

    if (!isDragging || !isDragMode) return;

    if (this.type === FenceType.Rect && this.rectangle) {
      this.rectangle.setBounds([dragStartPos, e.latlng]);
    } else if (this.type === FenceType.Circle && this.circle) {
      const radius = this.map.distance(this.circle.getLatLng(), e.latlng);
      this.circle.setRadius(radius);
    }
  }

  onMouseUp(e) {
    const { isDragMode } = this.state;
    this.setState({ isDragging: false });

    if (!isDragMode) return;

    if (this.type === FenceType.Rect && this.rectangle) {
      this.polygonPoints = [
        this.rectangle.getBounds().getSouthWest(),
        this.rectangle.getBounds().getSouthEast(),
        this.rectangle.getBounds().getNorthEast(),
        this.rectangle.getBounds().getNorthWest(),
      ];
    } else if (this.type === FenceType.Circle && this.circle) {
      this.polygonPoints = [];
      for (let i = 0; i < 360; i += 20) {
        if (isMobile) {
          return;
        }
        this.polygonPoints.push(this.getPointOnCircle(i));
      }
    } else if (this.type === FenceType.Polygon) {
      if (isMobile) {
        return;
      }
      this.polygonPoints.push(e.latlng);

      if (this.polygon) {
        this.polygon.addLatLng(e.latlng);
      } else {
        this.polygon = L.polygon([this.polygonPoints], { color: '#30f', weight: 1 }).addTo(this.map);
      }
    }
  }

  onTouchStart(e) {
    if (isMobile) {
      const touch = e.touches[0];
      const latlng = this.map.layerPointToLatLng([touch.clientX, touch.clientY]);

      this.firstPoint = latlng;

      if (this.type === FenceType.Circle) {
        this.setState({ firstClick: latlng, showTooltip: true, tooltipPosition: latlng });
        this.addMarker(latlng, 1);
      } else if (this.type === FenceType.Rect) {
        this.setState({ firstClick: latlng, showTooltip: true, tooltipPosition: latlng });
        this.addMarker(latlng, 1);
      }
    }
  }

  onTouchMove(e) {
    if (isMobile) {
      const touch = e.touches[0];
      const latlng = this.map.layerPointToLatLng([touch.clientX, touch.clientY]);

      if (this.type === FenceType.Circle && this.firstPoint) {
        const radius = this.map.distance(this.firstPoint, latlng);
        this.clearCircle();
        this.circle = L.circle(this.firstPoint, { radius, color: '#30f', weight: 1 }).addTo(this.map);
      } else if (this.type === FenceType.Rect && this.firstPoint) {
        this.clearRectangle();
        this.rectangle = L.rectangle([this.firstPoint, latlng], { color: '#30f', weight: 1 }).addTo(this.map);
      }
    }
  }

  onTouchEnd(e) {
    if (isMobile) {
      const touch = e.changedTouches[0];
      const latlng = this.map.layerPointToLatLng([touch.clientX, touch.clientY]);

      if (this.type === FenceType.Circle && this.firstPoint) {
        const radius = this.map.distance(this.firstPoint, latlng);
        this.clearCircle();
        this.circle = L.circle(this.firstPoint, { radius, color: '#30f', weight: 1 }).addTo(this.map);
        this.polygonPoints = [];
        for (let i = 0; i < 360; i += 20) {
          this.polygonPoints.push(this.getPointOnCircle(i));
        }
        this.firstPoint = null;
        this.setState({ showTooltip: false });
        this.clearMarkers();
      } else if (this.type === FenceType.Rect && this.firstPoint) {
        this.clearRectangle();
        this.rectangle = L.rectangle([this.firstPoint, latlng], { color: '#30f', weight: 1 }).addTo(this.map);
        this.polygonPoints = [
          this.rectangle.getBounds().getSouthWest(),
          this.rectangle.getBounds().getSouthEast(),
          this.rectangle.getBounds().getNorthEast(),
          this.rectangle.getBounds().getNorthWest(),
        ];
        this.firstPoint = null;
        this.setState({ showTooltip: false });
        this.clearMarkers();
      }
    }
  }

  addMarker(latlng, number) {
    const marker = L.marker(latlng, {
      icon: L.divIcon({
        className: 'custom-div-icon',
        html: `<div class="number-marker" style="font-size: 20px;">${number}</div>`,
        iconSize: [25, 25],
      }),
    }).addTo(this.map);
    this.setState((prevState) => ({
      markers: [...prevState.markers, marker],
    }));
  }

  clearMarkers() {
    const { markers } = this.state;
    markers.forEach((marker) => {
      this.map.removeLayer(marker);
    });
    this.setState({ markers: [] });
  }

  getPointOnCircle(degrees) {
    const center = this.circle.getLatLng();
    const radius = this.circle.getRadius();

    const theta = degrees * (Math.PI / 180); // convert degrees to radians

    // calculate the point on the circle in Cartesian coordinates
    const x = radius * Math.cos(theta);
    const y = radius * Math.sin(theta);

    // convert Cartesian coordinates to geographical coordinates
    const earthRadius = 6371000; // Earth's radius in meters
    const latRadians = y / earthRadius;
    const lngRadians = x / (earthRadius * Math.cos(center.lat * (Math.PI / 180)));

    // convert radians to degrees
    const latDegrees = latRadians * (180 / Math.PI);
    const lngDegrees = lngRadians * (180 / Math.PI);

    // add the center's coordinates to get the final point
    const lat = center.lat + latDegrees;
    const lng = center.lng + lngDegrees;

    return L.latLng(lat, lng);
  }

  getInfo() {
    const { currentDevice } = this.state;
    if (this.componentIsMounted) {
      this.controller.abort();
      let url = `${FleetGeofenceAPIUrl}getFence?userId=${GetLoginInfo().userId}&companyId=${GetLoginInfo().companyId}`;
      if (currentDevice.device_id !== '') {
        url += `&deviceId=${currentDevice.device_id}`;
      }
      FetchAccessToken().then((accessToken) => {
        axios
          .get(url,
            {
              headers: { Authorization: `${accessToken}` },
              signal: this.controller.signal,
            })
          .then((response) => {
            if (this.componentIsMounted === true) {
              this.dataReceive = true;
              if (response.data.data && response.data.data.length > 0) {
                // Clear the current markers
                this.clearAll();
                const coordinates = [];
                for (let i = 0; i < response.data.data.length; i += 2) {
                  const latlng = L.latLng(response.data.data[i], response.data.data[i + 1]);
                  this.polygonPoints.push(latlng);
                  coordinates.push([response.data.data[i], response.data.data[i + 1]]);
                }
                const bounds = L.latLngBounds(coordinates);
                this.map.fitBounds(bounds);
                this.polygon = L.polygon(this.polygonPoints).addTo(this.map);
                this.setState({ firstClick: null, showTooltip: false });
              } else {
                console.log('No data!!!');
                this.clearAll();
              }
            }
          })
          .catch((e) => {
            console.log('Error', e);
          });
      }).catch((error) => {
        console.log('Token fetch error:', error);
      });
    }
  }

  getVehicleInfo() {
    if (this.componentIsMounted) {
      this.vehicleController.abort();
      FetchAccessToken().then((accessToken) => {
        axios
          .get(`${FleetDeviceAPIUrl}findAllDevice?userId=${GetLoginInfo().userId}`, {
            headers: { Authorization: `${accessToken}` },
            signal: this.vehicleController.signal,
          })
          .then((response) => {
            if (this.componentIsMounted === true) {
              const deviceList = response.data.data;
              if (deviceList.length > 0) {
                deviceList.unshift(
                  {
                    car_num: i18n.t('all_vehicles'),
                    device_id: '',
                    last_location: [0, 0],
                  },
                );
                this.setState({
                  deviceList,
                });
              }
            }
          })
          .catch((e) => {
            console.log('Error', e);
          });
      }).catch((error) => {
        console.log('Token fetch error:', error);
      });
    }
  }

  handleTypeValueChange(value) {
    this.type = value;
    this.polygonPoints.length = 0;
    this.clearPolygon();
    this.clearRectangle();
    this.clearCircle();
    this.clearMarkers();
    this.getInfo();

    // 如果选择的是多边形，禁用拖曳模式并禁用按钮
    if (value === FenceType.Polygon) {
      this.setState({ isDragMode: false, disableDragModeButton: true });
      this.map.dragging.disable();
    } else {
      this.setState({ disableDragModeButton: false });
    }

    this.setState({
      minPointsCountCheck: true, maxPointsCountCheck: true, saveSuccess: false,
    });
  }

  handleSave() {
    const { currentDevice } = this.state;
    let minPointsCountCheck = true;
    let maxPointsCountCheck = true;
    let valid = false;
    if (this.polygonPoints.length < 3) {
      minPointsCountCheck = false;
    } else if (this.polygonPoints.length > 20) {
      maxPointsCountCheck = false;
    } else {
      valid = true;
    }
    this.setState({
      minPointsCountCheck, maxPointsCountCheck,
    });
    if (valid === false) {
      return;
    }
    const tempData = {
      userId: GetLoginInfo().userId,
      companyId: GetLoginInfo().companyId,
      deviceId: currentDevice.device_id,
      points: [],
    };
    for (let i = 0; i < this.polygonPoints.length; i += 1) {
      tempData.points.push(this.polygonPoints[i].lat);
      tempData.points.push(this.polygonPoints[i].lng);
    }
    FetchAccessToken().then((accessToken) => {
      axios.post(`${FleetGeofenceAPIUrl}postFence`, tempData, {
        headers: {
          Authorization: `${accessToken}`,
        },
      })
        .then((response) => {
          this.setState({
            saveSuccess: true,
          }, () => {
            window.setTimeout(() => {
              this.setState({ saveSuccess: false });
            }, 3000);
            this.getInfo();
          });
        })
        .catch((error) => {
          console.error('Error posting fence data:', error);
        });
    }).catch((error) => {
      console.error('Token fetch error:', error);
    });
    this.clearAll();
    this.polygonPoints = [];
  }

  clearAll() {
    this.map.eachLayer((layer) => {
      // 保留底图
      if (layer instanceof L.TileLayer) return;
      this.map.removeLayer(layer);
    });
    this.rectangle = null;
    this.circle = null;
    this.polygon = null;
    this.polygonPoints = [];
    this.clearMarkers();
  }

  handleClear() {
    this.clearAll();
    this.setState({
      minPointsCountCheck: true, maxPointsCountCheck: true, saveSuccess: false,
    });
  }

  clearRectangle() {
    if (this.rectangle) {
      this.map.removeLayer(this.rectangle);
      this.rectangle = null;
    }
  }

  clearCircle() {
    if (this.circle) {
      this.map.removeLayer(this.circle);
      this.circle = null;
    }
  }

  clearPolygon() {
    if (this.polygon) {
      this.map.removeLayer(this.polygon);
      this.polygon = null;
    }
  }

  vehicleSelectCallback = (device) => {
    this.setState(
      {
        currentDevice: {
          device_id: device.split('@')[0],
          car_num: device.split('@')[1],
        },
      },
      () => {
        this.getInfo();
      },
    );
  };

  initMap() {
    this.streetMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      detectRetina: true,
      maxZoom: 19,
      maxNativeZoom: 20,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });
    this.darkMap = L.tileLayer(
      'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      {
        detectRetina: true,
        maxZoom: 19,
        maxNativeZoom: 20,
        attribution:
          '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
      },
    );
    this.map = L.map('map', {
      center: this.initCoordinate,
      zoom: 17,
      zoomControl: true,
      width: '100%',
      layers: [this.streetMap],
      dragging: !this.state.isDragMode,
    });

    this.map.on('dragstart', () => {
      if (!this.state.isDragMode) {
        this.map.stop();
      }
    });

    this.map.on('zoomstart', () => {
      if (!this.state.isDragMode) {
        this.map.stop();
      }
    });

    const baseMaps = {
      Streets: this.streetMap,
      Night: this.darkMap,
      Bright: this.brightMap,
    };

    this.controlLayer = L.control.layers(baseMaps);
    this.controlLayer.addTo(this.map);
    L.control.scale({ position: 'bottomright' }).addTo(this.map);
    const CustomControl = L.Control.extend({
      options: {
        position: 'bottomleft',
      },

      onAdd() {
        const container = L.DomUtil.create('div', 'custom-control');
        container.innerHTML = `
          <div style="background-color: white; padding: 10px; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); display: flex; align-items: center;">
            <div style="width: 30px; height: 30px; background-color: #30f; opacity: 0.2; margin-right: 5px;"></div>
            <span style="font-size: 20px;">${i18n.t('geofencing_current_range')}</span>
            <div style="width: 30px; height: 30px; background-color: #3388ff; opacity: 0.2; margin-left: 10px; margin-right: 5px;"></div>
            <span style="font-size: 20px;">${i18n.t('geofencing_adjusted_range')}</span>
          </div>
        `;
        return container;
      },
    });

    // 添加自定义控件到地图
    this.map.addControl(new CustomControl());
  }

  initMarkerGroup() {
    this.markerGroup = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
      iconCreateFunction(cluster) {
        return L.divIcon({
          html: `<b>${cluster.getChildCount()}</b>`,
          className: 'statistic-page-map-custom-marker-cluster',
          iconAnchor: [30, 60],
          iconSize: [50, 50],
        });
      },
    });
  }

  render() {
    const {
      minPointsCountCheck, maxPointsCountCheck, saveSuccess, deviceList, currentDevice,
      isDragMode, showTooltip, tooltipPosition,
    } = this.state;

    return (
      <div className="full-page-container-geofencing">
        <div className="settings-geofencing container">
          <div className="settings-geofencing title-row">
            <div className="settings-geofencing type-group">
              <div className="settings-button-group">
                <SettingsButtonGroup
                  name=""
                  options={[{ label: i18n.t('option_geofencing_rect'), value: 'rect' },
                    { label: i18n.t('option_geofencing_circle'), value: 'circle' },
                    { label: i18n.t('option_geofencing_polygon'), value: 'polygon' }]}
                  initialSelectedOption="rect"
                  onValueChange={this.handleTypeValueChange}
                />
              </div>
            </div>
            <div className="settings-geofencing vehicle">
              {deviceList.length > 0 && (
                <VehicleDropdown
                  carNum={currentDevice.car_num}
                  deviceList={deviceList}
                  callbackFunction={this.vehicleSelectCallback.bind(this)}
                />
              )}
            </div>

            <Button
              className="clear-button"
              variant="success"
              onClick={this.handleClear}
              style={{ margin: '10px' }}
            >
              {i18n.t('button_clear')}
            </Button>
            <Button
              className="save-button"
              variant="success"
              onClick={this.handleSave}
              style={{ margin: '10px' }}
            >
              {i18n.t('button_save')}
            </Button>
          </div>

          <Collapse in={minPointsCountCheck === false}>
            <div className="collapse alert">
              <Alert key="danger" variant="danger" className="collapse alert-style">
                {i18n.t('min_count_warning')}
              </Alert>
            </div>
          </Collapse>
          <Collapse in={maxPointsCountCheck === false}>
            <div className="collapse alert">
              <Alert key="danger" variant="danger" className="collapse alert-style">
                {i18n.t('max_count_warning')}
              </Alert>
            </div>
          </Collapse>
          <Collapse in={saveSuccess === true}>
            <div className="collapse alert">
              <Alert key="save" variant="success" className="collapse alert-style">
                {i18n.t('save_successfully')}
              </Alert>
            </div>
          </Collapse>
          <div
            className="settings-geofencing description"
            style={{ display: window.innerWidth >= 1280 ? 'block' : 'block' }}
          >
            <div className="geofencing-description">
              <p><strong>{i18n.t('geofencing_title')}</strong></p>
              <ul>
                <li>
                  <strong>{i18n.t('option_geofencing_rect')}</strong>
                  {' '}
                  {i18n.t('geofencing_rectangles')}
                </li>
                <li>
                  <strong>{i18n.t('option_geofencing_circle')}</strong>
                  {' '}
                  {i18n.t('geofencing_circles')}
                </li>
                <li>
                  <strong>{i18n.t('option_geofencing_polygon')}</strong>
                  {' '}
                  {i18n.t('geofencing_polygons')}
                </li>
              </ul>
            </div>
          </div>
          <div className="settings-geofencing map-container" id="map" />
          {showTooltip && tooltipPosition && (
            <Overlay target={document.getElementById('map')} show={showTooltip} placement="top">
              {(props) => (
                <Tooltip id="tooltip" {...props}>
                  {i18n.t('first_point')}
                </Tooltip>
              )}
            </Overlay>
          )}

        </div>
      </div>
    );
  }
}

SettingsPageGeofencing.propTypes = {
  deviceId: PropTypes.string,
};

SettingsPageGeofencing.defaultProps = {
  deviceId: '',
};

export default SettingsPageGeofencing;
