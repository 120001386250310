import axios from 'axios';
import { FetchAccessToken } from '@/firebase';
import { FleetDeviceAPIUrl } from '@/components/GlobalDefine';

const tick = (device, instant_location, map, deviceController) => {
  if (instant_location) {
    deviceController.abort();
    FetchAccessToken().then((accessToken) => {
      axios
        .get(
          `${FleetDeviceAPIUrl}findByDevice?deviceId=${device.device_id}&userId=${GetLoginInfo().userId}`,
          {
            headers: { Authorization: `${accessToken}` },
            signal: deviceController.signal,
          },
        )
        .then((response) => {
          if (map) {
            if (device.last_location) {
              const instance = L.motion.polyline(
                [
                  device.last_location,
                  response.data.data[0].last_location,
                ],
                {
                  color: 'transparent',
                },
                {
                  auto: true,
                  duration: 1000,
                },
                {
                  removeOnEnd: false,
                  showMarker: true,
                  icon: carIcon(),
                },
              );

              map.addLayer(instance);
              if (
                Math.abs(response.data.data[0].last_location[0] - device.last_location[0]) > 0.001 ||
                Math.abs(response.data.data[0].last_location[1] !== device.last_location[1]) > 0.001
              ) {
                map.flyTo(
                  new L.LatLng(response.data.data[0].last_location[0], response.data.data[0].last_location[1]),
                  18,
                  {
                    animate: true,
                    duration: 0.7,
                  },
                );
              }
            }
          }
        })
        .catch((e) => {
          console.log('Error', e);
        });
    }).catch((error) => {
      console.log('Token fetch error:', error);
    });
  }
};

export default tick;
