import L from 'leaflet';
import moment from 'moment';

const animateMarker = (marker, startLatLng, endLatLng, duration = 1000, intervalRef) => {
  const frameRate = 60; // 60 frames per second
  const totalFrames = duration / (1000 / frameRate);
  let frame = 0;

  const deltaLat = (endLatLng.lat - startLatLng.lat) / totalFrames;
  const deltaLng = (endLatLng.lng - startLatLng.lng) / totalFrames;

  // Clear any existing interval
  if (intervalRef.current) clearInterval(intervalRef.current);

  intervalRef.current = setInterval(() => {
    if (frame < totalFrames) {
      const currentLat = startLatLng.lat + deltaLat * frame;
      const currentLng = startLatLng.lng + deltaLng * frame;
      marker.setLatLng([currentLat, currentLng]);
      frame++;
    } else {
      clearInterval(intervalRef.current);
      marker.setLatLng([endLatLng.lat, endLatLng.lng]); // Ensure it ends exactly at the target
    }
  }, 1000 / frameRate);
};

const findSurroundingPoints = (trajectoryData, currentPlayMoment) => {
  let prevPoint = null;
  let nextPoint = null;

  for (let i = 0; i < trajectoryData.length - 1; i += 1) {
    const pointTime = moment(trajectoryData[i].created_at);
    const nextPointTime = moment(trajectoryData[i + 1].created_at);

    if (currentPlayMoment.isBetween(pointTime, nextPointTime, null, '[)')) {
      const timeDifference = nextPointTime.diff(pointTime, 'minutes');

      if (timeDifference <= 10) {
        prevPoint = trajectoryData[i];
        nextPoint = trajectoryData[i + 1];
      }
      break;
    }
  }

  return { prevPoint, nextPoint };
};

const updateMovingPoint = (map, trajectoryData, currentPlayTime, movingMarkerRef) => {
  if (!trajectoryData || trajectoryData.length === 0 || !currentPlayTime) return;

  // Clear any ongoing animation before starting a new one
  if (movingMarkerRef.current && movingMarkerRef.current.animationInterval) {
    clearInterval(movingMarkerRef.current.animationInterval);
  }

  const currentPlayMoment = moment(currentPlayTime);

  // Find the two points surrounding the current play time
  let { prevPoint, nextPoint } = findSurroundingPoints(trajectoryData, currentPlayMoment);

  if (!prevPoint) {
    // if (movingMarkerRef.current) {
    //   movingMarkerRef.current.setLatLng([trajectoryData[0].latitude, trajectoryData[0].longitude]);
    // }
    return;
  }
  if (!nextPoint) {
    // if (movingMarkerRef.current) {
    //   movingMarkerRef.current.setLatLng([trajectoryData[trajectoryData.length - 1].latitude,
    //     trajectoryData[trajectoryData.length - 1].longitude]);
    // }
    return;
  }

  const totalDuration = moment(nextPoint.created_at).diff(moment(prevPoint.created_at), 'milliseconds');
  const elapsedTime = currentPlayMoment.diff(moment(prevPoint.created_at), 'milliseconds');
  const ratio = elapsedTime / totalDuration;

  const interpolatedLat = prevPoint.latitude + ratio * (nextPoint.latitude - prevPoint.latitude);
  const interpolatedLng = prevPoint.longitude + ratio * (nextPoint.longitude - prevPoint.longitude);
  const interpolatedPosition = { lat: interpolatedLat, lng: interpolatedLng };

  if (!movingMarkerRef.current) {
    movingMarkerRef.current = L.marker([interpolatedLat, interpolatedLng], {
      icon: L.divIcon({ className: 'moving-point', html: '<div class="moving-pin"></div>', iconSize: [30, 30] }),
    }).addTo(map);
    movingMarkerRef.current.animationInterval = { current: null }; // Initialize interval reference
  } else {
    const currentLatLng = movingMarkerRef.current.getLatLng();
    animateMarker(movingMarkerRef.current, currentLatLng, interpolatedPosition, 1000, movingMarkerRef.current.animationInterval); // Pass interval reference
  }
};

export default updateMovingPoint;
