import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, Spinner } from 'react-bootstrap';
import '@/components/css/vehicle_page/VehiclePageTimeLine.css';
import 'react-datepicker/dist/react-datepicker.css';
import i18n from '../../../i18n';
import findTimeLineData from './utils/VehiclePageTimeLine/api/timelineDataService';
import TimelineBar from './utils/VehiclePageTimeLine/components/TimelineBar';
import TimelineLegend from './utils/VehiclePageTimeLine/components/TimelineLegend';
import TimelineLabels from './utils/VehiclePageTimeLine/components/TimelineLabels';
import PlaybackButtonGroup from './utils/VehiclePageTimeLine/components/PlaybackButtonGroup';

const VehiclePageTimeLine = ({ deviceId, dateStart, onCurrentPlayTimeChange }) => {
  const [timelineData, setTimelineData] = useState([]);
  const [dataReceive, setDataReceive] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [currentPlayTime, setCurrentPlayTime] = useState(moment(dateStart).startOf('day'));
  const [firstRecord, setFirstRecord] = useState(null);
  const [lastRecord, setLastRecord] = useState(null);
  const [timeDifference, setTimeDifference] = useState(null);
  const Duration = 60;

  const controller = new AbortController();
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const getInfo = async () => {
      try {
        const queryDate = moment(dateStart).startOf('day').utc();
        const { timelineData, idlePeriods } = await findTimeLineData(deviceId, queryDate, controller);
        setTimelineData(timelineData);
        setDataReceive(true);
        // Find the first element with status "active"
        // Find the first and last elements with status "idle" or "active"
        const firstR = timelineData.find((item) => item.status === 'idle' || item.status === 'active');
        const lastR = [...timelineData].reverse().find((item) => item.status === 'idle' || item.status === 'active');

        if (firstR) {
          // Calculate the percentage point of the day's total time (1440 minutes)

          // Set currentPlayTime to the specific time of the percentage (truncate to minute)
          const newPlayTime = moment(dateStart).startOf('day').add(firstR.time * 10, 'minutes').startOf('minute');
          setCurrentPlayTime(newPlayTime);

          // Define firstRecord and lastRecord as moment objects
          const firstRec = moment(dateStart).startOf('day').add(firstR.time * 10, 'minutes').startOf('minute');
          const lastRec = moment(dateStart).startOf('day').add((lastR.time * 10) + 10, 'minutes').endOf('minute');

          setFirstRecord(firstRec);
          setLastRecord(lastRec);

          // Calculate the time difference in minutes
          setTimeDifference(lastRec.diff(firstRec, 'minutes'));
        }
      } catch (error) {
        console.error('Error fetching timeline data:', error);
        setDataReceive(true);
      }
    };

    setCurrentPlayTime(moment(dateStart).startOf('day')); // 更新 currentPlayTime
    getInfo();

    return () => {
      controller.abort();
    };
  }, [dateStart, deviceId]);

  useEffect(() => {
    // 監控 currentPlayTime 和 dateStart 是否在同一天
    if (!moment(currentPlayTime).isSame(dateStart, 'day')) {
      setCurrentPlayTime(moment(dateStart).startOf('day')); // 調整到 dateStart 當天的 00:00:00
    }
  }, [currentPlayTime, dateStart]);

  useEffect(() => {
    onCurrentPlayTimeChange(currentPlayTime);
  }, [currentPlayTime]);

  const handleBackward = () => {
    const newTime = currentPlayTime.clone().subtract(1, 'hour');
    if (newTime.isBefore(firstRecord)) {
      setCurrentPlayTime(firstRecord);
    } else {
      setCurrentPlayTime(newTime);
    }
  };

  const handleForward = () => {
    const newTime = currentPlayTime.clone().add(1, 'hour');
    if (newTime.isAfter(lastRecord)) {
      setCurrentPlayTime(lastRecord);
    } else {
      setCurrentPlayTime(newTime);
    }
  };

  const handleFastForward = () => {
    const newTime = currentPlayTime.clone().add(4, 'hours');
    if (newTime.isAfter(lastRecord)) {
      setCurrentPlayTime(lastRecord);
    } else {
      setCurrentPlayTime(newTime);
    }
  };

  const handlePlayPause = () => {
    if (playing) {
      clearInterval(intervalId);
      setIntervalId(null);
    } else {
      const id = setInterval(() => {
        setCurrentPlayTime((prevTime) => {
          const newTime = prevTime.clone().add(1, 'minutes');

          // Check if newTime is within the bounds of firstRecord and lastRecord
          if (newTime.isAfter(lastRecord)) {
            clearInterval(id);
            setPlaying(false);
            return lastRecord; // Set to lastRecord if it exceeds the limit
          } if (newTime.isBefore(firstRecord)) {
            return firstRecord; // Ensure we don't go before firstRecord
          }
          return newTime;
        });
      }, 1000);
      setIntervalId(id);
    }
    setPlaying(!playing);
  };

  const handleTimeSelect = (selectedTime) => {
    if (moment(selectedTime).isSame(dateStart, 'day')) {
      setCurrentPlayTime(moment(selectedTime));
    } else {
      setCurrentPlayTime(moment(dateStart).startOf('day'));
    }
    if (playing) {
      handlePlayPause();
    }
  };

  return (
    <div>
      <Card className="vehicle-page-timeline-card container">
        <div>
          <Card.Title className="vehicle-page-timeline-card title">
            {i18n.t('info_timeline_title')}
          </Card.Title>
        </div>
        {!dataReceive && (
          <div className="spinner-container" style={{ margin: 'auto' }}>
            <Spinner className="spinner" animation="border" />
          </div>
        )}
        {dataReceive && (
          <div id="fade-in">
            <div className="timeline-container">
              <TimelineLegend />
              <TimelineBar
                timelineData={timelineData}
                currentPlayTime={currentPlayTime.toDate()}
                dataStart={dateStart}
                onTimeSelect={handleTimeSelect}
              />
              <TimelineLabels />
            </div>
            <div className="button-container" style={{ marginTop: 80 }}>
              <PlaybackButtonGroup
                onBackward={handleBackward}
                onPlayPause={handlePlayPause}
                onForward={handleForward}
                onFastForward={handleFastForward}
                isPlaying={playing}
                currentPlayTime={currentPlayTime}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

VehiclePageTimeLine.propTypes = {
  deviceId: PropTypes.string,
  dateStart: PropTypes.instanceOf(Date),
  onCurrentPlayTimeChange: PropTypes.func.isRequired,
};

VehiclePageTimeLine.defaultProps = {
  deviceId: '',
  dateStart: new Date(),
};

export default VehiclePageTimeLine;
