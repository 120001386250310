// utils/VehiclePageMapUtils.js



// Haversine formula to calculate distance between two points
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
  + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180))
  * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
}

export const createTrajectoryLayer = (coordinates) => {
    const trajectoryType = coordinates.length > 1 ? 'MultiLineString' : 'LineString';
    const trajectory = {
      type: trajectoryType,
      coordinates: coordinates.length > 1 ? coordinates : coordinates[0],
    };
    return L.geoJSON().addData(trajectory);
  }
  
  export const createMotionPolyline = (from, to, options) => {
    return L.motion.polyline(
      [from, to],
      {
        color: 'transparent',
      },
      {
        auto: true,
        duration: 1000,
      },
      {
        removeOnEnd: false,
        showMarker: true,
        icon: carIcon(),
      },
    );
  }