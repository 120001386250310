import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';

const TimelineBar = ({ timelineData, currentPlayTime, dataStart, onTimeSelect }) => {
  const [playTimeMinutes, setPlayTimeMinutes] = useState(0);
  const [isSameDay, setIsSameDay] = useState(false);

  useEffect(() => {
    const playTimeMinutes = moment(currentPlayTime).hour() * 60 + moment(currentPlayTime).minute();
    setPlayTimeMinutes(playTimeMinutes);
    setIsSameDay(moment(currentPlayTime).isSame(dataStart, 'day'));
  }, [currentPlayTime, dataStart]);

  const handleBarClick = (event) => {
    const totalMinutes = 1440; // Total minutes in a day
    const barRect = event.currentTarget.getBoundingClientRect(); // Get the dimensions of the overlay
    const clickPosition = event.clientX - barRect.left; // Adjust for the position relative to the overlay
    const barWidth = barRect.width; // Get the width of the overlay

    const percentage = (clickPosition / barWidth) * 100;
    const selectedMinutes = Math.floor((percentage / 100) * totalMinutes);

    // Update the play time
    const selectedTime = moment(dataStart).startOf('day').add(selectedMinutes, 'minutes');
    onTimeSelect(selectedTime);
  };

  const renderBar = (data) => {
    let type = 'vehicle-page-timeline-card offline';
    if (data.status === 'active') {
      type = 'vehicle-page-timeline-card active';
    } else if (data.status === 'idle') {
      type = 'vehicle-page-timeline-card idle';
    } else if (data.status === 'test') {
      type = 'vehicle-page-timeline-card test';
    }

    return (
      <ProgressBar className={type} now={data.duration} key={data.time} />
    );
  };

  const renderCurrentTimeIndicator = () => {
    const totalMinutes = 1440; // Total minutes in a day
    const percentage = (playTimeMinutes / totalMinutes) * 100;

    return (
      <div
        className="current-time-indicator"
        style={{
          left: `${percentage}%`,
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '4px',
          backgroundColor: 'red', // Indicator color
          zIndex: 0,
        }}
      />
    );
  };

  return (
    <div className="vehicle-page-timeline-card graph-container">
      <ProgressBar max={1440}>
        {timelineData.map((data) => renderBar(data))}
      </ProgressBar>
      {isSameDay && renderCurrentTimeIndicator()}
      {/* Expanded clickable overlay */}
      <div
        className="click-overlay"
        onClick={handleBarClick}
        style={{
          position: 'absolute',
          top: '-10px', // Adjust height above the ProgressBar
          bottom: '-10px', // Adjust height below the ProgressBar
          left: 0,
          right: 0,
          cursor: 'pointer',
          zIndex: 1, // Ensure the overlay is above the ProgressBar
        }}
      />
    </div>
  );
};

TimelineBar.propTypes = {
  timelineData: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.number,
      duration: PropTypes.number,
      status: PropTypes.string,
    })
  ).isRequired,
  currentPlayTime: PropTypes.instanceOf(Date).isRequired,
  dataStart: PropTypes.instanceOf(Date).isRequired,
  onTimeSelect: PropTypes.func.isRequired, // Prop for time selection
};

export default TimelineBar;
