import React, { useState, useEffect } from 'react';
import i18n from 'i18next'; // Ensure i18n is imported
import { useTranslation } from 'react-i18next';
import VehicleUtilizationChart from './VehicleUtilizationChart'; // Import chart
import { fetchVehicleUtilization } from '../../utils/Api_helper';

const VehicleUtilization = ({ deviceId, carNum }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [avgEngine, setAvgEngine] = useState(0);
  const [avgIdle, setAvgIdle] = useState(0);
  const [avgMileage, setAvgMileage] = useState(0);

  const [currentSumEngine, setCurrentSumEngine] = useState(0);
  const [currentSumIdle, setCurrentSumIdle] = useState(0);
  const [currentSumMileage, setCurrentSumMileage] = useState(0);

  const [highlightWorkingStatus, setHighlightWorkingStatus] = useState('');
  const [highlightMileageStatus, setHighlightMileageStatus] = useState('');
  const [highlightUsability, setHighlightUsability] = useState('');

  // New state to track if there's data for the specific carNum
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    const fetchAndProcessVehicleUtilization = async () => {
      try {
        const data = await fetchVehicleUtilization(deviceId);

        if (data.length > 0) {
          const uniqueDeviceIds = [...new Set(data.map((v) => v.device_id))];
          const uniqueDeviceCount = uniqueDeviceIds.length;

          const totalEngine = data.reduce((acc, v) => acc + v.engine_duration, 0);
          const totalIdle = data.reduce((acc, v) => acc + v.idle_duration, 0);
          const totalMileage = data.reduce((acc, v) => acc + v.mileage, 0);

          // Include current device's values in average calculation
          const matchingDeviceData = data.filter((v) => v.device_id === deviceId);
          const totalCurrentEngine = matchingDeviceData.reduce((acc, v) => acc + v.engine_duration, 0);
          const totalCurrentIdle = matchingDeviceData.reduce((acc, v) => acc + v.idle_duration, 0);
          const totalCurrentMileage = matchingDeviceData.reduce((acc, v) => acc + v.mileage, 0);

          // Update counts for average calculations
          const totalCount = uniqueDeviceCount + (matchingDeviceData.length > 0 ? 1 : 0); // Include self if data exists

          setAvgEngine(totalEngine / totalCount);
          setAvgIdle(totalIdle / totalCount);
          setAvgMileage((totalMileage) / totalCount);

          // Current device data
          if (matchingDeviceData.length > 0) {
            setCurrentSumEngine(totalCurrentEngine);
            setCurrentSumIdle(totalCurrentIdle);
            setCurrentSumMileage(totalCurrentMileage);

            // Set hasData to true if the carNum exists in matchingDeviceData
            setHasData(matchingDeviceData.some((v) => v.car_num === carNum));

            // const idleRatio = totalCurrentEngine > 0 ? totalCurrentIdle / totalCurrentEngine : 0;
            // const avgIdleRatio = avgEngine > 0 ? avgIdle / avgEngine : 0;

            // if (idleRatio > avgIdleRatio * 1.1) {
            //   setHighlightWorkingStatus(t('idle_time_abnormal', { carNum: matchingDeviceData[0].car_num }));
            // }

            if (totalCurrentMileage < avgMileage * 0.8) {
              setHighlightMileageStatus(t('mileage_low', { carNum: matchingDeviceData[0].car_num }));
            }

            if (totalCurrentEngine < 9600) {
              setHighlightUsability(t('utilization_low', { carNum: matchingDeviceData[0].car_num }));
            }
          } else {
            // Set hasData to false if there are no records for the specific carNum
            setHasData(false);
          }
        } else {
          setHasData(false);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAndProcessVehicleUtilization();
  }, [deviceId, carNum, t]);

  if (isLoading) {
    return <div>{t('loading_message')}</div>;
  }

  if (error) {
    return <div>{t('error_message', { error })}</div>;
  }

  return (
    <div style={{
      padding: '20px', backgroundColor: 'var(--bgColorNormal)', borderRadius: '8px', color: 'white', width: '690px', margin: 'auto',
    }}
    >
      <h3 className="widget-title">{t('vehicle_highlights_title')}</h3>

      {hasData ? (
        <>
          <p>{highlightWorkingStatus}</p>
          <p>{highlightMileageStatus}</p>
          <p>{highlightUsability}</p>

          <VehicleUtilizationChart
            currentSumEngine={currentSumEngine}
            currentSumIdle={currentSumIdle}
            avgEngine={avgEngine}
            avgIdle={avgIdle}
            currentSumMileage={currentSumMileage}
            avgMileage={avgMileage}
            carNum={carNum}
          />
        </>
      ) : (
        <div className="no-data-message">
          {i18n.t('error_no_data')}
        </div>
      )}
    </div>
  );
};

export default VehicleUtilization;
