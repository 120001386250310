import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,
} from 'chart.js';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// BarChart Component
const BarChart = ({ eventNames, personalValues, averageValues }) => {
  const { t } = useTranslation(); // Get translation function

  const data = {
    labels: eventNames,
    datasets: [
      {
        label: t('personal_stats'), // Use translation for label
        data: personalValues,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: t('average_stats'), // Use translation for label
        data: averageValues,
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: 'white', // Set Y-axis tick color to white
        },
        title: {
          display: true,

          color: 'white', // Set Y-axis title color to white
        },
      },
      x: {
        ticks: {
          color: 'white', // Set X-axis tick color to white
        },
        title: {
          display: true,

          color: 'white', // Set X-axis title color to white
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'white', // Set legend labels color to white
        },
      },
      tooltip: {
        titleColor: 'white', // Set tooltip title color to white
        bodyColor: 'white', // Set tooltip body color to white
      },
    },
  };

  return <Bar data={data} options={options} />;
};

const DriverEventStatisticsWidget = ({
  driverId,
  driverName,
  eventStats,
  averageStats = {},
}) => {
  const { t } = useTranslation();

  // Destructure eventStats
  const {
    DA,
    OR,
    SL,
    PH,
    SM,
    FT,
    TTC,
    LDW,
    HMW,
    HACC,
    HBR,
    HTUR,
  } = eventStats;

  // Destructure averageStats
  const {
    DA: avgDA = 0,
    OR: avgOR = 0,
    SL: avgSL = 0,
    PH: avgPH = 0,
    SM: avgSM = 0,
    FT: avgFT = 0,
    TTC: avgTTC = 0,
    LDW: avgLDW = 0,
    HMW: avgHMW = 0,
    HACC: avgHACC = 0,
    HBR: avgHBR = 0,
    HTUR: avgHTUR = 0,
  } = averageStats;

  return (
    <div>
      <h5>{t('driver_event_statistics_for', { driverName })}</h5>
      {' '}
      {/* Changed to h3 for smaller size */}
      <div style={{
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '16px',
        margin: '16px 0',
        backgroundColor: '#f9f9f9',
      }}
      >
        <p style={{ color: 'black' }}>{t('driver_page_statistics_description')}</p>
      </div>
      {/* DMS Info Group */}
      <h6>{t('dms_info')}</h6>
      <BarChart
        eventNames={['DA', 'OR', 'SL', 'PH', 'SM', 'FT']}
        personalValues={[DA, OR, SL, PH, SM, FT]}
        averageValues={[avgDA, avgOR, avgSL, avgPH, avgSM, avgFT]}
      />

      {/* ADAS Info Group */}
      <h6>{t('adas_info')}</h6>
      <BarChart
        eventNames={['TTC', 'LDW', 'HMW']}
        personalValues={[TTC, LDW, HMW]}
        averageValues={[avgTTC, avgLDW, avgHMW]}
      />

      {/* Harsh Info Group */}
      <h6>{t('harsh_info')}</h6>
      <BarChart
        eventNames={['HACC', 'HBR', 'HTUR']}
        personalValues={[HACC, HBR, HTUR]}
        averageValues={[avgHACC, avgHBR, avgHTUR]}
      />
    </div>
  );
};

export default DriverEventStatisticsWidget;
