// src/services/timelineDataService.js
import axios from 'axios';
import moment from 'moment';
import { FleetDeviceAPIUrl } from '@/components/GlobalDefine';
import { FetchAccessToken } from '@/firebase';

const findTimeLineData = async (deviceId, queryDate, controller) => {
  const bin = Array(144).fill(0);
  try {
    const accessToken = await FetchAccessToken();
    const response = await axios.get(
      `${FleetDeviceAPIUrl}findWholeDayVehicleStatusByTime?deviceId=${deviceId}&time=${queryDate.format('YYYY-MM-DD HH:mm:ss').replace('+', '%2b')}`,
      {
        headers: { Authorization: accessToken },
        signal: controller.signal,
      },
    );

    const timeData = [];
    let lastStatus = 'inactive';
    let lastStatusStartTime = queryDate;
    const idlePeriods = [];

    if (response.data.data.length === 0) {
      timeData.push({
        time: -1,
        duration: 1440,
        status: 'inactive',
      });
      return { timelineData: timeData, idlePeriods };
    }

    response.data.data.forEach((item) => {
      const diff = moment(item.created_at).diff(queryDate);
      const duration = moment.duration(diff).asMinutes() / 10.0;
      const index = Math.floor(duration);

      if (item.speed >= 5.0) {
        bin[index] += 20;
      } else {
        bin[index] += 1;
      }
    });

    bin.forEach((value, i) => {
      let type = 'inactive';
      if (value === 0) {
        type = 'inactive';
      } else if (value < 1200) {
        type = 'idle';
      } else {
        type = 'active';
      }

      if (type !== lastStatus) {
        if (lastStatus === 'idle') {
          idlePeriods.push({
            startTime: lastStatusStartTime.format('HH:mm'),
            endTime: queryDate.clone().add(i * 10, 'minutes').format('HH:mm'),
          });
        }
        lastStatus = type;
        lastStatusStartTime = queryDate.clone().add(i * 10, 'minutes');
      }

      timeData.push({
        time: i,
        duration: 10,
        status: type,
      });
    });

    if (lastStatus === 'idle') {
      idlePeriods.push({
        startTime: lastStatusStartTime.format('HH:mm'),
        endTime: queryDate.clone().add(1440, 'minutes').format('HH:mm'),
      });
    }

    return { timelineData: timeData, idlePeriods };
  } catch (error) {
    console.error('Error fetching timeline data:', error);
    throw error;
  }
};

export default findTimeLineData;
