let LoginInfo = {
  account: '',
  userId: '',
  companyId: '',
  loginStatus: false,
};

function GetLoginInfo() {
  return LoginInfo;
}

const IsDemoAccount = () => GetLoginInfo().userId === 'FSkG9Uh4B9TxdYT3PSOtjAARLD73';

function SetLoginInfo(info) {
  LoginInfo = info;
}

export {
  GetLoginInfo, SetLoginInfo, IsDemoAccount,
};
