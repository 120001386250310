// VehiclePageMap/utils/mapUtils.js
import L from 'leaflet';
import moment from 'moment';
import { ThemeIcon } from '@/components/EventTypeVariables';

// Initialize the map
export const initMap = (initCoordinate) => {
  const streetMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    detectRetina: true,
    errorTileUrl: ThemeIcon('no_connection'),
    maxZoom: 19,
    maxNativeZoom: 20,
    attribution:
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
  });
  
  const darkMap = L.tileLayer(
    'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
    {
      detectRetina: true,
      maxZoom: 19,
      maxNativeZoom: 20,
      attribution:
        '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    },
  );
  
  const brightMap = L.tileLayer(
    'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png',
    {
      detectRetina: true,
      maxZoom: 19,
      maxNativeZoom: 20,
      attribution:
        '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    },
  );
  
  const map = L.map('map', {
    center: initCoordinate,
    zoom: 17,
    layers: [streetMap],
  });

  // Add layer control to the map
  const baseMaps = {
    Streets: streetMap,
    Night: darkMap,
    Bright: brightMap,
  };
  L.control.layers(baseMaps).addTo(map);
  map.addControl(new L.Control.Fullscreen());
  L.control.scale({ position: 'bottomright' }).addTo(map);

  return map;
};

// Find the nearest point based on current time
export const findNearestPoint = (data, currentTime) => {
  let nearestPoint = null;
  let minDiff = Infinity;
  data.forEach((point) => {
    const diff = Math.abs(moment(point.created_at).diff(currentTime));
    if (diff < minDiff) {
      minDiff = diff;
      nearestPoint = point;
    }
  });
  return nearestPoint;
};

export const clearMapLayers = (map, greenPinMarkersRef, movingMarkerRef, startMarkerRef, endMarkerRef) => {
  if (map) {
    greenPinMarkersRef.current.clearLayers();
    if (movingMarkerRef.current) {
      map.removeLayer(movingMarkerRef.current);
      movingMarkerRef.current = null;
    }
    if (startMarkerRef.current) {
      map.removeLayer(startMarkerRef.current);
      startMarkerRef.current = null;
    }
    if (endMarkerRef.current) {
      map.removeLayer(endMarkerRef.current);
      endMarkerRef.current = null;
    }
  }
};