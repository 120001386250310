const instantBtnOnClick = (e, instant_location, device, map, deviceController) => {
    if (instant_location) {
      map.removeLayer(instance);
      const marker = L.marker([device.start_location[0], device.start_location[1]], {
        icon: MapIcon('vehicle', 100, this.onDuty),
      });
  
      marker.addTo(map);
      marker.bindPopup(customPopup(device), customPopupOptions);
      this.setState({ instant_location: !instant_location });
    } else {
      removeMapMarker();
      deviceController.abort();
      FetchAccessToken().then((accessToken) => {
        axios
          .get(
            `${FleetDeviceAPIUrl}findByDevice?deviceId=${device.device_id}&userId=${GetLoginInfo().userId}`,
            {
              headers: { Authorization: `${accessToken}` },
              signal: deviceController.signal,
            },
          )
          .then((response) => {
            if (this.componentIsMounted === true) {
              removeMapMarker();
              if (instance !== undefined) {
                map.removeLayer(instance);
              }
              map.flyTo(new L.LatLng(response.data.data[0].last_location[0], response.data.data[0].last_location[1]), 18);
              this.setState({ device: response.data.data[0], instant_location: !instant_location });
            }
          })
          .catch((e) => {
            console.log('Error', e);
          });
      }).catch((error) => {
        console.log('Token fetch error:', error);
      });
    }
  };
  
  export default instantBtnOnClick;
  