// src/services/timelineDataService.js
import axios from 'axios';
import moment from 'moment';
import { FleetIdleAPIUrl, FleetFormalAPIUrl,FleetDeviceAPIUrl } from '@/components/GlobalDefine';
import { FetchAccessToken } from '@/firebase';
import L from 'leaflet';
import { MapIcon,ThemeIcon } from '@/components/EventTypeVariables';
import i18n from '@/i18n';
import iconIdle from '@/textures/materials/icon_idle/icon_idle.png';

export const getVehicleTrajectory = async (deviceId, dateStart) => {
  const sampleRate = 1;
  const vehicleUrl = `${FleetDeviceAPIUrl}findVehicleTrajectoryByTimeRange?deviceId=${deviceId
  }&afterOf=${moment(dateStart).startOf('day').format().replace('+', '%2b')}}
    &beforeOf=${moment(dateStart).endOf('day').format().replace('+', '%2b')}}
    &sampleRate=${sampleRate}`;
  const accessToken = await FetchAccessToken();
  const response = await axios.get(vehicleUrl, {
    headers: { Authorization: `${accessToken}` },
  });
  return response.data.data;
}

export const getDeviceLocation = async (deviceId, userId) => {
  const deviceUrl = `${FleetDeviceAPIUrl}findByDevice?deviceId=${deviceId}&userId=${userId}`;
  const accessToken = await FetchAccessToken();
  const response = await axios.get(deviceUrl, {
    headers: { Authorization: `${accessToken}` },
  });
  return response.data.data[0];
}
let idleMarkers = []; // Array to hold the markers

export const getIdleLocation = async (deviceId, dateStart, map, device, onDuty) => {

  const timeStart = moment(dateStart).startOf('day').format('YYYY-MM-DD');
  const IdleUrl = `${FleetIdleAPIUrl}findIdleLocationByDeviceId?device_id=${deviceId}&timeStart=${timeStart}`;


  try {
    const accessToken = await FetchAccessToken();

    const response = await axios.get(IdleUrl, {
      headers: {
        Authorization: `${accessToken}`,
        withCredentials: false,
      },
    });


    // Clear existing markers from the map
    idleMarkers.forEach(marker => {
      map.removeLayer(marker);
    });
    idleMarkers = []; // Clear the markers array

    if (response.data.data && response.data.data.length > 0) {

      const idleLocations = response.data.data;
      let idleDuration = 3; // 累計怠速時間
      let lastIdleTime = null; // 上一個怠速點的時間
      let lastLocation = null; // 上一個怠速點的位置

      idleLocations.forEach((idleData, index) => {
        const { location } = idleData;
        const time = moment(idleData.time);

        // 計算怠速時間
        if (lastIdleTime) {
          const timeDiff = Math.abs(time.diff(lastIdleTime, 'minutes', true));

          if (timeDiff < 2) {
            idleDuration += timeDiff;
          } else {
            // 時間差大於等於 2 分鐘，生成 markerContent 並顯示圖標
            // if (lastLocation) {
            //   createIdleMarker(lastLocation, lastIdleTime, idleDuration, map, device, onDuty);
            // }

            // 重置怠速時間和位置
            idleDuration = 3;
            lastIdleTime = time;
            lastLocation = location;
          }
        } else {
          // 第一個怠速點，初始化 lastIdleTime 和 lastLocation
          lastIdleTime = time;
          lastLocation = location;
        }

        // 處理最後一個點
        if (index === idleLocations.length - 1 && lastLocation) {
          // createIdleMarker(lastLocation, lastIdleTime, idleDuration, map, device, onDuty);
        }
      });
    } 
  } catch (error) {
    console.error('Error fetching idle location:', error);
  }
}


export const createIdleMarker = (location, lastIdleTime, idleDuration, map, device, onDuty) => {
  
  const url = FleetFormalAPIUrl.substring(0, FleetFormalAPIUrl.length - 1);
  const imgSrc = isValidImageUrl(`${url}${device.image_path}`)
    ? `${url}${device.image_path}`
    : ThemeIcon('avatar', 50, onDuty);

  const markerContent = `
    <div style="display: flex; align-items: center;">
      <img src="${imgSrc}" style="height: 50px; width: 50px; margin-right: 10px;" />
      <div>
        <div>${i18n.t('info_notif_idle')}</div>
        <div>${device.car_num}</div> 
        <div>${i18n.t('info_time')}：${lastIdleTime.format('YYYY/MM/DD HH:mm')}</div>
        <div>${i18n.t('info_total_idle_hours')}：${idleDuration.toFixed(1)}${i18n.t('info_time_for_minute')}</div> 
      </div>
    </div>`;

  const marker = L.marker([location[0], location[1]], {
    icon: L.icon({
      iconUrl: iconIdle,
      iconSize: [50, 50],
      iconAnchor: [25, 50],
      popupAnchor: [0, -50],
      className: 'idle-marker'
    }),
    zIndexOffset: -1000 // Set a low z-index to ensure it's at the bottom
  });

  marker.bindPopup(markerContent);
  marker.addTo(map);
  
  idleMarkers.push(marker); // Store the marker reference
  
}

const isValidImageUrl = (imageUrl) => {
  try {
    const img = new Image();
    img.src = imageUrl;
    return img.complete && img.naturalWidth !== 0;
  } catch (error) {
    console.log(error);
    return false;
  }
}
