// drawTrajectory.js
import { calculateDistance } from "../calc/VehiclePageMapUtils";

const drawTrajectory = async (map, deviceData) => {
  try {
    // 清除旧的轨迹图层
    map.eachLayer((layer) => {
      if (layer instanceof L.GeoJSON) {
        map.removeLayer(layer);
      }
    });

    let lostConnection = 0;
    let trajectoryLayer = null;

    if (typeof deviceData !== 'undefined' && deviceData.length > 0) {
      const sortedData = deviceData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      let coordinates = [];
      for (let i = 0; i < sortedData.length; i += 1) {
        if (i > 0) {
          const prevDate = new Date(sortedData[i - 1].created_at);
          const currentDate = new Date(sortedData[i].created_at);
          const diffMinutes = (currentDate - prevDate) / (1000 * 60);
          if (diffMinutes > 5) {
            const distance = calculateDistance(sortedData[i - 1].latitude, sortedData[i - 1].longitude, sortedData[i].latitude, sortedData[i].longitude);
            if (distance > 1) {
              lostConnection += 1;
            }
            if (coordinates.length > 0) {
              coordinates.push([]); // Push an empty array to signify a new segment
            }
            continue; // Skip linking this point to the previous one
          }
        }
        if (coordinates.length === 0 || coordinates[coordinates.length - 1].length === 0) {
          coordinates.push([]);
        }
        coordinates[coordinates.length - 1].push([
          sortedData[i].longitude,
          sortedData[i].latitude,
        ]);
      }

      const isValidCoordinate = (coord) => coord !== 0 && coord < 180 && coord > -180;
      coordinates = coordinates
        .filter((segment) => segment.length > 0)
        .map((segment) => segment.filter((value) => value.every(isValidCoordinate)))
        .filter((segment) => segment.length > 0);

      const trajectoryType = coordinates.length > 1 ? 'MultiLineString' : 'LineString';
      const trajectory = {
        type: trajectoryType,
        coordinates: coordinates.length > 1 ? coordinates : coordinates[0],
      };
      trajectoryLayer = L.geoJSON().addTo(map);
      trajectoryLayer.addData(trajectory);
    }

    return {
      lost: lostConnection,
      layer: trajectoryLayer
    };
  } catch (e) {
    console.log('Error', e);
    return {
      lost: 0,
      layer: null
    };
  }
};

export default drawTrajectory;
