import React from 'react';

const TimelineLabels = () => {
  const dot = [];
  for (let i = 0; i <= 48; i += 1) {
    const dotColor = i % 4 === 0 ? 'orangered' : 'gray';
    const dotRadius = i % 4 === 0 ? 3 : 1.5;
    const temp = {
      x: 12.5 * i,
      color: dotColor,
      radius: dotRadius,
    };
    dot.push(temp);
  }

  return (
    <svg className="vehicle-page-timeline-card label-container">
      {dot.map((info) => (
        <circle key={info.x} cx={info.x + 70} cy="30" r={info.radius} fill={info.color} />
      ))}
      <text x="50" y="60" fill="white">00:00</text>
      <text x="100" y="60" fill="white">02:00</text>
      <text x="150" y="60" fill="white">04:00</text>
      <text x="200" y="60" fill="white">06:00</text>
      <text x="250" y="60" fill="white">08:00</text>
      <text x="300" y="60" fill="white">10:00</text>
      <text x="350" y="60" fill="white">12:00</text>
      <text x="400" y="60" fill="white">14:00</text>
      <text x="450" y="60" fill="white">16:00</text>
      <text x="500" y="60" fill="white">18:00</text>
      <text x="550" y="60" fill="white">20:00</text>
      <text x="600" y="60" fill="white">22:00</text>
      <text x="650" y="60" fill="white">24:00</text>
    </svg>
  );
};

export default TimelineLabels;