import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../css/event_page/EventPageInfo.css';
import EventPageInfoCard from './EventPageInfoCard';
import EventPageChartCard from './EventPageChartCard';
// import EventPageMessageCard from './EventPageMessageCard';
import EventPageVideoCard from './EventPageVideoCard';
import '../../css/Scrollable.css';
import { Row, Col } from 'react-bootstrap'; // 引入 Row 和 Col 組件

const EventPageInfo = ({
  eventId,
  deviceId,
  driverId,
  eventTime,
  riskType,
  name,
  carNum,
}) => {
  const [progress, setProgress] = useState(58);
  const [isWideScreen, setIsWideScreen] = useState(true);



  const onProgressCallback = (time) => {
    setProgress(time);
  };

  const isBehavior = () => {
    if (riskType === undefined) {
      return false;
    }

    return (
      riskType === 'COLLISION' ||
      riskType === 'FCW' ||
      riskType === 'TTC' ||
      riskType === 'HMW' ||
      riskType === 'LDW' ||
      riskType === 'LC' ||
      riskType === 'HACC' ||
      riskType === 'HBR' ||
      riskType === 'HTUR'
    );
  };

  return (
    <div className="event-page-info main-row">
      <Row style={{width:'100%'}}>
        {isWideScreen ? (
          <>
            <Col xs={6} >
             
                <EventPageInfoCard
                  eventId={eventId}
                  driverId={driverId}
                  
                  eventTime={eventTime}
                  name={name}
                  carNum={carNum}
                />
                <EventPageVideoCard
                  deviceId={deviceId}
                  eventId={eventId}
                  riskType={riskType}
                  eventTime={eventTime}
                  progressCallback={onProgressCallback}
                />
              
            </Col>
            <Col xs={6} >
              <EventPageChartCard eventId={eventId} progress={progress} />
            </Col>
          </>
        ) : (
          <Col xs={8} className="event-page-info info-column" style={{width:'100%'}}>
            <div className="scrollable-container event">
              <EventPageInfoCard
                eventId={eventId}
                driverId={driverId}
                eventTime={eventTime}
                name={name}
                carNum={carNum}
                deviceId={deviceId}
                riskType={riskType}
                progressCallback={onProgressCallback}
              />
              <EventPageVideoCard
                deviceId={deviceId}
                eventId={eventId}
                riskType={riskType}
                eventTime={eventTime}
                progressCallback={onProgressCallback}
              />
              <br />
              <EventPageChartCard eventId={eventId} progress={progress} />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

EventPageInfo.propTypes = {
  eventId: PropTypes.string,
  deviceId: PropTypes.string,
  driverId: PropTypes.string,
  eventTime: PropTypes.string,
  riskType: PropTypes.string,
  name: PropTypes.string,
  carNum: PropTypes.string,
};

EventPageInfo.defaultProps = {
  eventId: '',
  deviceId: '',
  driverId: '',
  eventTime: '',
  riskType: '',
  name: '',
  carNum: '',
};

export default EventPageInfo;