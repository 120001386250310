import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import moment from 'moment';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getIdleLocation, getVehicleTrajectory } from './utils/VehiclePageMap/api/VehicleMapService';
import { MapIcon } from '../../EventTypeVariables';
import '@/components/css/vehicle_page/VehiclePageMap.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rotatedmarker';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import tick from './utils/VehiclePageTimeLine/api/tick';
import drawTrajectory from './utils/VehiclePageMap/components/drawTrajectory';
import instantBtnOnClick from './utils/VehiclePageMap/components/instantBtnOnClick';
import { initMap, clearMapLayers } from './utils/VehiclePageMap/components/mapUtils';
import MapLegend from './utils/VehiclePageMap/components/MapLegend';
import updateMovingPoint from './utils/VehiclePageMap/components/updateMovingPoint';
import {
  addStartMarker, removeStartMarker, addEndMarker, removeEndMarker,
} from './utils/VehiclePageMap/components/markerUtils';

const zoomToScaleDistance = {
  19: 20,
  18: 50,
  17: 100,
  16: 200,
  15: 300,
  14: 500,
  13: 1000,
  12: 3000,
  11: 5000,
  10: 10000,
  9: 20000,
  8: 50000,
  7: 100000,
  6: 200000,
  5: 300000,
  4: 500000,
  3: 1000000,
  2: 3000000,
  1: 5000000,
  0: 10000000,
};

const zoomToSampleRate = {
  19: 1, // Sample every point
  18: 1, // Sample every point
  17: 1, // Sample every point
  16: 1, // Sample every 2 points
  15: 1, // Sample every 3 points
  14: 1, // Sample every 4 points
  13: 1, // Sample every 5 points
  12: 1, // Sample every 5 points
  11: 1, // Sample every 5 points
  10: 1, // Sample every 5 points
  9: 1, // Sample every 5 points
  8: 1, // Sample every 5 points
  7: 5, // Sample every 5 points
  6: 5, // Sample every 5 points
  5: 10, // Sample every 10 points
  4: 10, // Sample every 10 points
  3: 10, // Sample every 10 points
  2: 10, // Sample every 10 points
  1: 10, // Sample every 10 points
  0: 10, // Sample every 10 points
};

const VehiclePageMap = ({
  device, onDuty, dateStart, currentPlayTime, instant_location,
}) => {
  const [map, setMap] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(null);
  const [scaleDistance, setScaleDistance] = useState(null);
  const [deviceData, setDeviceData] = useState(null);
  const [sampledDeviceData, setSampledDeviceData] = useState(null); // Store sampled data
  const [lostConnection, setLostConnection] = useState(0);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [movingPoint, setMovingPoint] = useState(null);
  const greenPinMarkersRef = useRef(L.layerGroup());
  const movingMarkerRef = useRef(null);
  const startMarkerRef = useRef(null);
  const endMarkerRef = useRef(null);

  useEffect(() => {
    if(!map){
    const initCoordinate = [device.last_location[0], device.last_location[1]];
    const mapInstance = initMap(initCoordinate);
    setMap(mapInstance);

    updateScaleDistance(mapInstance.getZoom());

    // Add device last location marker
    const marker = L.marker(initCoordinate, {
      icon: MapIcon('vehicle', 100, onDuty),
    });

    greenPinMarkersRef.current.addTo(mapInstance);

    // Refresh device data every second
    const interval = setInterval(() => tick(device, instant_location, mapInstance), 1000);
    return () => clearInterval(interval);
  }
  }, [device]);

  const updateScaleDistance = (zoomLevel) => {
    const scale = zoomToScaleDistance[zoomLevel] || 0;
    setScaleDistance(scale);
  };

  useEffect(() => {
    if (map) {
      map.on('zoomend', () => {
        const zL = map.getZoom();
        setZoomLevel(zL);
        updateScaleDistance(zL);
      });
    }
  }, [map]);

  useEffect(() => {
    setDeviceData([]);
    if (dateStart && map) {
      clearMapLayers(map, greenPinMarkersRef, movingMarkerRef, startMarkerRef, endMarkerRef);

      getVehicleTrajectory(device.device_id, dateStart).then((data) => {
        // Sort data by created_at in ascending order
        const sortedData = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        // Store the original data
        setDeviceData(sortedData);
        if (sortedData.length !== 0) {
          // Get the SOF and EOF objects
          const SOF = sortedData[0]; // Start of Frame - first object
          const EOF = sortedData[sortedData.length - 1]; // End of Frame - last object
          // Calculate the difference in seconds between EOF and SOF created_at timestamps
          const startDate = new Date(SOF.created_at);
          const endDate = new Date(EOF.created_at);
          const timeDifferenceInSeconds = (endDate - startDate) / 1000;

          const samplingRate = 600 / timeDifferenceInSeconds;
          let sampledData = sortedData;

          // 10 FPS * 60 seconds. We want to play the trajectory within 60 seconds.
          // If samplingRate is less than 1, sample the data
          if (samplingRate < 1) {
            const step = Math.ceil(1 / samplingRate);
            sampledData = sortedData.filter((_, index) => index % step === 0);
          }

          setSampledDeviceData(sortedData);
          if (sortedData.length > 0) {
            setStartPoint([sortedData[0].latitude, sortedData[0].longitude]);
            setEndPoint([sortedData[sortedData.length - 1].latitude, sortedData[sortedData.length - 1].longitude]);
          }

          drawTrajectory(map, sortedData).then((result) => {
            setLostConnection(result.lost);
          });
        } else {
          if (movingMarkerRef.current) {
            map.removeLayer(movingMarkerRef.current);
          }
          drawTrajectory(map, []).then((result) => {
          });
        }
      });

      getIdleLocation(device.device_id, dateStart, map, device, onDuty);
    }
  }, [dateStart, map, zoomLevel]); // Added zoomLevel to dependencies

  useEffect(() => {
    if (map && startPoint && endPoint && currentPlayTime && deviceData.length !== 0) {
      const playDate = moment(currentPlayTime).format('YYYY-MM-DD');
      const startDate = moment(dateStart).format('YYYY-MM-DD');

      if (startDate === playDate) {
        addStartMarker(map, startPoint, startMarkerRef);
      } else {
        removeStartMarker(map, startMarkerRef);
      }

      if (startDate === playDate) {
        addEndMarker(map, endPoint, endMarkerRef);
      } else {
        removeEndMarker(map, endMarkerRef);
      }
    }
  }, [map, startPoint, endPoint, currentPlayTime, dateStart, deviceData]);

  useEffect(() => {
    if (map) {
      // Ensure there is data available for moving points
      const dataToUse = sampledDeviceData && sampledDeviceData.length > 0 ? sampledDeviceData : deviceData;

      if (dataToUse && dataToUse.length > 0) {
        updateMovingPoint(map, dataToUse, currentPlayTime, movingMarkerRef);
      }
    }
  }, [currentPlayTime, sampledDeviceData, deviceData, map]);

  const handleInstantBtnOnClick = (e) => {
    instantBtnOnClick(e, instant_location, device, map);
  };

  return (
    <div className="vehicle-page-map container">
      <MapLegend map={map} />
      { instant_location ? (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Realtime</Tooltip>}
        >
          <Button variant="light" className="vehicle-page-map instant_location-button live" onClick={handleInstantBtnOnClick}>
            <div />
          </Button>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Realtime</Tooltip>}
        >
          <Button variant="light" className="vehicle-page-map instant_location-button unlive" disabled={!onDuty} onClick={handleInstantBtnOnClick}>
            <div />
          </Button>
        </OverlayTrigger>
      )}
      <div className="vehicle-page-map map-container" id="map" />
    </div>
  );
};

VehiclePageMap.propTypes = {
  device: PropTypes.object.isRequired,
  dateStart: PropTypes.string.isRequired,
  onDuty: PropTypes.bool.isRequired,
  currentPlayTime: PropTypes.string.isRequired,
  instant_location: PropTypes.bool.isRequired,
};

export default VehiclePageMap;
