import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

// 定义自定义字体样式
const customFont = {
  family: 'Arial, sans-serif',
  size: 16,
  weight: '600'
};

// 圓餅圖元件
const PieChartComponent = ({ engineDuration, idleDuration, colors, spacing }) => {
  const { t } = useTranslation();

  const data = {
    labels: [t('engine_duration'), t('idle_duration')],
    datasets: [
      {
        data: [engineDuration, idleDuration],
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        top: 5,
        bottom: 5,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.raw || 0;
            const percentage = (value / (engineDuration + idleDuration) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
      legend: {
        display: true,
        labels: {
          color: 'white',
          font: customFont
        },
      },
      datalabels: {
        display: true,
        formatter: (value, context) => {
          const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${percentage}%`;
        },
        color: 'white',
        font: customFont
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div style={{ marginTop: spacing || '30px' }}>
      <Pie data={data} options={options} />
    </div>
  );
};

// 條形圖元件
const BarChartComponent = ({ label, metricName, specificValue, averageValue }) => {
  const { t } = useTranslation();

  const data = {
    labels: [label],
    datasets: [
      {
        label: metricName,
        backgroundColor: '#36A2EB',
        data: [specificValue],
      },
      {
        label: t('average_label'),
        backgroundColor: '#FFCE56',
        data: [averageValue],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          color: 'white',
          font: customFont
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
          font: customFont
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      y: {
        ticks: {
          color: 'white',
          font: customFont
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export { PieChartComponent, BarChartComponent };
