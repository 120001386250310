import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from './VideoPlayer'; // Adjust the path
import {
  FleetFormalAPIUrl, 
  IsAdasEvent, 
  IsCollisionEvent,
} from '../../GlobalDefine';
import i18n from '../../../i18n';
import '../../css/event_page/EventPageVideoCard.css';
import NoVideo from '../../../textures/no-video.png';
import Loading from '../../../textures/loading.gif';
import PlayRes from '../../../textures/play.png';
import PauseRes from '../../../textures/pause.png'; 

function EventPlayVideoCard(props) {
  const [videoUrls, setVideoUrls] = useState([]);
  const [videoExists, setVideoExists] = useState([false, false, false]);
  const [isLoading, setIsLoading] = useState([true, true, true]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [masterTime, setMasterTime] = useState(0);
  const [individualTimes, setIndividualTimes] = useState([0, 0, 0]);
  const [videosReady, setVideosReady] = useState(0);
  const [syncEnabled, setSyncEnabled] = useState(true);
  const seekingRef = useRef(false);
  const playerRefs = useRef([]);

  const { deviceId, riskType, eventId, progressCallback } = props;

  useEffect(() => {
    const fetchVideoUrls = async () => {
      let computedUrls = [];
      if (IsCollisionEvent(riskType)) {
        computedUrls = [
          `${FleetFormalAPIUrl}colli_event_record/${deviceId}/Record/Front/${riskType}_${eventId}/index.m3u8`,
          `${FleetFormalAPIUrl}colli_event_record/${deviceId}/Record/Cabin/${riskType}_${eventId}/index.m3u8`,
          `${FleetFormalAPIUrl}colli_event_record/${deviceId}/Record/Rear/${riskType}_${eventId}/index.m3u8`,
        ];
      } else if (IsAdasEvent(riskType)) {
        computedUrls = [
          `${FleetFormalAPIUrl}adas_event_record/${deviceId}/Record/Front/${riskType}_${eventId}/index.m3u8`,
          `${FleetFormalAPIUrl}adas_event_record/${deviceId}/Record/Cabin/${riskType}_${eventId}/index.m3u8`,
        ];
      } else {
        computedUrls = [
          `${FleetFormalAPIUrl}dms_event_record/${deviceId}/Record/${riskType}_${eventId}/index.m3u8`,
        ];
      }
      setVideoUrls(computedUrls);
      playerRefs.current = computedUrls.map(() => React.createRef());

      const existArray = await Promise.all(computedUrls.map(videoExists));
      setVideoExists(existArray);
      setIsLoading(existArray.map(() => false));
    };

    const videoExists = async (url) => {
      try {
        const response = await fetch(`${url}?timestamp=${Date.now()}`);
        return response.ok;
      } catch (error) {
        return false;
      }
    };

    fetchVideoUrls();
  }, [deviceId, riskType, eventId]);

  const handlePlaybackToggle = () => {
    setIsPlaying(!isPlaying);
  };

  // This function will be called by any VideoPlayer instance to update the master time
  const handleSeek = (time, index) => {
    if (syncEnabled) { // Only seek if sync is enabled
      if (!seekingRef.current) { // Only seek if not already seeking
        seekingRef.current = true; // Set the flag to prevent rapid updates
        setMasterTime(time);

        // 同步所有影片的播放時間
        playerRefs.current.forEach((playerRef, i) => {
          if (i !== index && playerRef.current) {
            playerRef.current.seekTo(time);
          }
        });

        setTimeout(() => {
          seekingRef.current = false; // Reset the flag after a short delay
        }, 1000); // Adjust the delay as needed
      }
    } else {
      // 單獨影片的播放時間
      setIndividualTimes((prevTimes) => {
        const newTimes = [...prevTimes];
        newTimes[index] = time;
        return newTimes;
      });
    }
  };

  const handleCanPlayThrough = () => {
    setVideosReady((prevCount) => prevCount + 1);
    if (videosReady + 1 === videoUrls.length) {
      setIsPlaying(true);
    }
  };

  const handleProgressChanged = (progress) => {
    if (progressCallback) {
      progressCallback(progress);
    }
  };

  return (
    <div className="event-page-video-card container">
      {/* Sync Checkbox
      <div className="sync-checkbox-container">
        <input
          type="checkbox"
          id="sync-checkbox"
          checked={syncEnabled}
          onChange={handleSyncChange}
        />
        <label htmlFor="sync-checkbox"> Synchronize Playback </label>
      </div> */}

      <div
        className={videoUrls.length === 1
          ? 'event-page-video-card video-1-column'
          : 'event-page-video-card video-column'}
      >
        {/* Play/Pause Button */}
        <button
          type="button"
          className={`event-page-video-card ${isPlaying ? 'pause-button' : 'play-button'}`}
          onClick={handlePlaybackToggle}
        >
          <img src={isPlaying ? PauseRes : PlayRes} alt={isPlaying ? 'pause' : 'play'} />
        </button>

        <div className="event-page-video-card video-row">
          {videoUrls.map((url, index) => (
            <div key={index} className="event-page-video-card videoWrapper">
              {isLoading[index] && (
                <div className="event-page-video-card loading-wrapper">
                  <img 
                    src={Loading} 
                    alt="Loading..." 
                    style={{ 
                      width: '50px', 
                      height: '50px', 
                      position: 'absolute', 
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)' 
                    }} 
                  />
                </div>
              )}
              {!isLoading[index] && videoExists[index] && (
                <VideoPlayer
                  ref={playerRefs.current[index]}
                  url={url}
                  playing={isPlaying}
                  onSeek={(time) => handleSeek(time, index)} 
                  seekTime={syncEnabled ? masterTime : individualTimes[index]} // Pass masterTime if sync is enabled
                  onCanPlayThrough={handleCanPlayThrough}
                  onProgressChanged={handleProgressChanged}
                />
              )}
              {!isLoading[index] && !videoExists[index] && (
                <div className="event-page-video-card no-video-column">
                  <img className="event-page-video-card no-video" alt="" src={NoVideo} />
                  <div className="event-page-video-card no-video-text">
                    {i18n.t('no_video_available')}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

EventPlayVideoCard.defaultProps = {
  deviceId: '',
  riskType: '',
  eventId: '',
  progressCallback: () => {},
};

EventPlayVideoCard.propTypes = {
  deviceId: PropTypes.string,
  riskType: PropTypes.string,
  eventId: PropTypes.string,
  progressCallback: PropTypes.func,
};

export default EventPlayVideoCard;