import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartComponent, BarChartComponent } from '../../chart/chartFunction';
import '@/components/css/common/Description.css';

const VehicleUtilizationChart = ({
  currentSumEngine, currentSumIdle, avgEngine, avgIdle, currentSumMileage, avgMileage, carNum,
}) => {
  const { t } = useTranslation();

  const totalDuration = currentSumEngine + currentSumIdle;
  const enginePercentage = totalDuration > 0 ? ((currentSumEngine / totalDuration) * 100).toFixed(1) : 0;
  const idlePercentage = totalDuration > 0 ? ((currentSumIdle / totalDuration) * 100).toFixed(1) : 0;

  return (
    <div style={{
      marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center',
    }}
    >
      {/* <div className='description-content'>
        <p style={{ color: 'black' }}>{t('pie_chart_statistics_description')}</p>
      </div>

      <div className='bar-head-title-style'>
          <p style={{ color: 'white' }}>{t('working_status')}</p>
      </div>
      <div style={{ width: '400px', height: '200px' }}>

        <PieChartComponent
          engineDuration={currentSumEngine}
          idleDuration={currentSumIdle}
          enginePercentage={enginePercentage}
          idlePercentage={idlePercentage}
          colors={['#0000FF', '#008000']}
          labels={[t('engine_duration'), t('idle_duration')]}
        />
      </div> */}
      <div className="description-content">
        <p>{t('engine_time_vehicle_statistics_description')}</p>
      </div>
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px',
      }}
      >
        <div className="bar-head-title-style">
          <p style={{ color: 'white' }}>{t('engine_duration_comparison')}</p>
        </div>
        <div style={{ width: '400px', height: '300px', marginBottom: '20px' }}>
          <BarChartComponent
            label={carNum}
            metricName={t('engine_duration')}
            specificValue={currentSumEngine}
            averageValue={avgEngine}
            color="#0000FF"
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: 'white',
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    color: 'white',
                  },
                  grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                },
                y: {
                  ticks: {
                    color: 'white',
                  },
                  grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                },
              },
            }}
          />
        </div>

        {/* <div className='description-content'>
          <p>{t('idle_statistics_description')}</p>
        </div>
        <div style={{ width: '400px', height: '300px', marginBottom: '20px' }}>
          <BarChartComponent
            label={carNum}
            metricName={t('idle_duration')}
            specificValue={currentSumIdle}
            averageValue={avgIdle}
            color="#008000"
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: 'white',
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    color: 'white',
                  },
                  grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                },
                y: {
                  ticks: {
                    color: 'white',
                  },
                  grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                },
              },
            }}
          />
        </div> */}
        <div className="description-content">
          <p>{t('mileage_statistics_description')}</p>
        </div>
        <div className="bar-head-title-style">
          <p style={{ color: 'white' }}>{t('mileage_comparison')}</p>
        </div>
        <div style={{ width: '400px', height: '300px', marginBottom: '20px' }}>
          <BarChartComponent
            label={carNum}
            metricName={t('info_mileage')}
            specificValue={currentSumMileage}
            averageValue={avgMileage}
            color="#FF0000"
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: 'white',
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    color: 'white',
                  },
                  grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                },
                y: {
                  ticks: {
                    color: 'white',
                  },
                  grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleUtilizationChart;
