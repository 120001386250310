import React from 'react';
import i18n from 'i18next'; // Ensure i18n is imported
import DriverUtilizationChart from './DriverUtilizationChart';
import DriverEventStatisticsWidget from './DriverEventStatistics';
import useDriverUtilization from './useDriverUtilization';
import '../../../../../css/fleetInfo/DriverUtilizationWidget.css'; // Import CSS file

const DriverUtilizationWidget = ({ driverId, driverName }) => {
  const {
    driverUtilizationData,
    isLoading,
    currentSumEngine,
    currentSumIdle,
    avgEngine,
    avgIdle,
    highLightWorkingStatus,
    highLightUtilizationStatus,
    eventStats,
    hasData, // Destructure hasData from the hook
  } = useDriverUtilization(driverId, driverName);
  // Show loading message first
  if (!hasData && isLoading) {
    return (
      <div className="loading-message" style={{ margin: 'auto' }}>
        {i18n.t('loading')}
      </div>
    );
  }

  // If no data is available after loading
  if (hasData !== null && !hasData && !isLoading) {
    return (
      <div className="no-data-message">
        {i18n.t('error_no_data')}
      </div>
    );
  }

  // Format highlight messages for display
  const formattedHighLightWorkingStatus = highLightWorkingStatus.split('\n').map((line, index) => (
    <p key={index} className="highlight-text">{line}</p>
  ));

  const formattedHighLightUtilizationStatus = highLightUtilizationStatus.split('\n').map((line, index) => (
    <p key={index} className="highlight-text">{line}</p>
  ));

  return (
    <div className="driver-utilization-widget">
      <h3 className="widget-title">{i18n.t('driver_highlights')}</h3>

      <p>{formattedHighLightWorkingStatus}</p>
      <p>{formattedHighLightUtilizationStatus}</p>

      {/* Display personal messages if they exist */}
      {eventStats.personalMessages.length > 0 && (
        <div className="personal-messages">
          {eventStats.personalMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </div>
      )}

      {/* Render charts and statistics if data exists */}
      <DriverUtilizationChart
        currentSumEngine={currentSumEngine}
        currentSumIdle={currentSumIdle}
        avgEngine={avgEngine}
        avgIdle={avgIdle}
        driverName={driverName}
      />

      <DriverEventStatisticsWidget
        driverId={driverId}
        driverName={driverName}
        eventStats={eventStats.personal} // Pass personal statistics
        averageStats={eventStats.average} // Pass average statistics
      />
    </div>
  );
};

export default DriverUtilizationWidget;
